import {AuthInfo} from '@/models/account'
import {User} from '@/models/user'
import {localStorageService} from '@/services/local-storage-service'
import {reactive, toRefs, computed} from 'vue'

type State = {
  authInfo: AuthInfo | null
  isLoadingForSignin: boolean
  currentUser: User | null
}

const state = reactive<State>({
  authInfo: null,
  isLoadingForSignin: false,
  currentUser: null,
})

export function useSessionStore() {
  const isLogined = computed(() => {
    return !!state.authInfo?.token
  })

  const setAuthInfo = async (authInfo: AuthInfo | null) => {
    if (authInfo) {
      localStorageService.accessToken = authInfo.token
      state.authInfo = authInfo
      return
    }

    localStorageService.accessToken = ''
    state.authInfo = null
  }

  const clearAuthInfo = () => {
    localStorageService.removeToken()
    setAuthInfo(null)
  }

  const setIsLoaging = (status: boolean) => {
    state.isLoadingForSignin = status
  }

  return {
    isLogined,
    setAuthInfo,
    clearAuthInfo,
    setIsLoaging,
    ...toRefs(state),
  }
}

export type SessionStore = ReturnType<typeof useSessionStore>
