import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "px-4 py-2 text-left space-y-1 relative" }
const _hoisted_4 = { class: "text-sm font-bold text-clamp-1" }
const _hoisted_5 = { class: "font-normal text-xs text-gray-700 dark:text-gray-400 line-clamp-4" }
const _hoisted_6 = {
  key: 0,
  class: "text-base font-medium inline-block pr-4"
}
const _hoisted_7 = { class: "space-x-2 text-sm font-medium inline-block" }
const _hoisted_8 = {
  key: 0,
  class: "text-gray-400 line-through"
}
const _hoisted_9 = {
  key: 1,
  class: "text-green-lightShow"
}
const _hoisted_10 = { class: "text-sm font-medium text-green-lightShow" }
const _hoisted_11 = {
  key: 0,
  class: "absolute right-2 bottom-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeartIcon = _resolveComponent("HeartIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "bg-white m-auto shadow-md border border-gray-200 rounded-lg max-w-sm dark:bg-gray-800 dark:border-gray-700",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goVoucherDetail()))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          class: "rounded-t-lg aspect-video object-cover w-full",
          src: 
            // eslint-disable-next-line vue/no-mutating-props
            _ctx.voucher.images.sort((a, b) => {
              return (a.id as number) > (b.id as number) ? 1 : -1
            })[0].imageUrl
          ,
          alt: ""
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.voucher.name), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.voucher.description), 1),
        _createElementVNode("div", null, [
          (!!_ctx.voucher.discountedPrice || _ctx.voucher.discountedPrice === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " ￥" + _toDisplayString(_ctx.voucher.discountedPrice.toLocaleString()), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            (!!_ctx.voucher.discountedPrice || _ctx.voucher.discountedPrice === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, " ￥" + _toDisplayString(_ctx.voucher.price.toLocaleString()), 1))
              : _createCommentVNode("", true),
            (
                _ctx.voucher.discountType === _ctx.DiscountTypes.Amount ||
                _ctx.voucher.discountType === _ctx.DiscountTypes.Rate
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.voucher.discountValue.toLocaleString()) + " " + _toDisplayString(_ctx.SuffixDiscountValueLabels[_ctx.voucher.discountType]), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.voucher.summary), 1)
          ])
        ]),
        (_ctx.voucher.favorite)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_HeartIcon, { class: "h-6 w-6 text-red-show" })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}