import {AxiosError} from 'axios'

export class ApiSystemError extends Error {
  static DEFAULT_MESSAGE =
    'エラーが発生しています。しばらく経ってからもう一度お試しください。'

  constructor(message: string, cause?: Error) {
    super(message, {cause})
    this.name = 'ApiSystemError'
  }

  public static isSystemErrorAxiosResponse(e: AxiosError): boolean {
    const status = e.response?.status
    return status !== undefined && status >= 500 && status < 600
  }

  public static fromAxiosError(cause: AxiosError): ApiSystemError {
    return new ApiSystemError(ApiSystemError.DEFAULT_MESSAGE, cause)
  }
}
