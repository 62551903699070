class LocalStorageService {
  readonly StorageKeyAccessToken: string = 'access-token'
  readonly StorageKeySecret: string = 'secret'
  readonly StorageKeyLanguage: string = 'language'

  set accessToken(token: string) {
    localStorage.setItem(this.StorageKeyAccessToken, token)
  }

  get accessToken() {
    const token = localStorage.getItem(this.StorageKeyAccessToken) || ''
    return token
  }

  removeToken() {
    localStorage.removeItem(this.StorageKeyAccessToken)
  }

  //   set secret(secret: string) {
  //     localStorage.setItem(this.StorageKeySecret, secret);
  //   }

  //   get secret() {
  //     const secret = localStorage.getItem(this.StorageKeySecret) || "";
  //     return secret;
  //   }
}

export const localStorageService = new LocalStorageService()
