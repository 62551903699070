import {Empty} from '@/models/common/empty'
import {
  FetchVoucherReservesByReserveStatusIdRequest,
  PayVoucherReserveRequest,
  VerifyVoucherReserveRequest,
  VoucherReserveDetail,
  VoucherReserveSummary,
} from '@/models/voucher-reserve'
import {ApiOptions, ApiV1Service} from './common/api-service'

class VoucherReserveV1Service extends ApiV1Service {
  createVoucherReserve(voucherId: number, options?: ApiOptions) {
    return this.put(
      VoucherReserveDetail,
      `/vouchers/${voucherId}/reserve`,
      options,
    )
  }

  fetchVoucherReserves() {
    return this.getList(VoucherReserveSummary, `/reserves`)
  }

  fetchVoucherReservesByReserveStatusId(
    request: FetchVoucherReservesByReserveStatusIdRequest,
  ) {
    return this.getPaginatedList(VoucherReserveSummary, `/reserves`, request)
  }

  fetchVoucherReserve(voucherReserveId: number) {
    return this.get(VoucherReserveDetail, `/reserves/${voucherReserveId}`)
  }

  fetchVoucherReserveWithReceipt(voucherReserveId: number) {
    return this.get(
      VoucherReserveDetail,
      `/reserves/${voucherReserveId}/receipt`,
    )
  }

  verifyVoucherReserve(
    request: VerifyVoucherReserveRequest,
    options?: ApiOptions,
  ) {
    const {voucherReserveId, ...req} = request
    return this.post(
      Empty,
      `/reserves/${voucherReserveId}/verify`,
      req,
      options,
    )
  }

  payVoucherReserve(request: PayVoucherReserveRequest) {
    const {voucherReserveId, ...req} = request
    return this.post(Empty, `/reserves/${voucherReserveId}/payment`, req)
  }
}

export const voucherReserveService = new VoucherReserveV1Service()
