import {
  User,
  UserPaymentMethod,
  UserProfile,
  CreateUserPaymentMethodRequest,
  UserAddress,
} from '@/models/user'
import {userService} from '@/services/api.v1/user-service'
import {reactive, toRefs} from 'vue'

type State = {
  user: User
  userProfile: UserProfile
  userPaymentMethods: UserPaymentMethod[]
  userAddresses: UserAddress[]

  payjp: any
}

const state = reactive<State>({
  user: new User(),
  userProfile: new UserProfile(),
  userPaymentMethods: [],
  userAddresses: [],

  payjp: undefined,
})

export function useProfileStore() {
  const fetchUserProfile = async () => {
    const userProfile = await userService.fetchUserProfile()
    state.userProfile = userProfile
  }

  const updateUserProfile = async () => {
    const userProfile = await userService.updateUserProfile(state.userProfile)
    state.userProfile = userProfile
  }

  const fetchUserPaymentMethods = async () => {
    const res = await userService.fetchUserPaymentMethods()
    state.userPaymentMethods = res
  }

  const createOrUpdateUserPaymentMethod = async (
    request: CreateUserPaymentMethodRequest,
  ) => {
    await userService.createOrUpdateUserPaymentMethod(request)
    await fetchUserPaymentMethods()
  }

  const setDefaultUserPaymentMethod = async (userPaymentMethodId: string) => {
    await userService.setDefaultUserPaymentMethod(userPaymentMethodId)
    await fetchUserPaymentMethods()
  }

  const deleteUserPaymentMethod = async (userPaymentMethodId: string) => {
    await userService.deleteUserPaymentMethod(userPaymentMethodId)
    await fetchUserPaymentMethods()
  }

  const fetchUserAddresses = async () => {
    const res = await userService.fetchUserAddresses()
    state.userAddresses = res
  }

  const createOrUpdateUserAddress = async (request: UserAddress) => {
    await userService.createOrUpdateUserAddress(request)
    await fetchUserAddresses()
  }

  const setDefaultUserAddress = async (userAddressId: number) => {
    await userService.setDefaultUserAddress(userAddressId)
    await fetchUserAddresses()
  }

  const deleteUserAddress = async (userAddressId: number) => {
    await userService.deleteUserAddress(userAddressId)
    await fetchUserAddresses()
  }

  const initializePAYJP = () => {
    if (!state.payjp) {
      const {Payjp} = window
      state.payjp = Payjp(process.env.VUE_APP_PAYJP_PUBLIC_KEY)
    }
  }

  return {
    fetchUserProfile,
    updateUserProfile,
    createOrUpdateUserPaymentMethod,
    fetchUserPaymentMethods,
    createOrUpdateUserAddress,
    deleteUserPaymentMethod,
    setDefaultUserPaymentMethod,
    fetchUserAddresses,
    deleteUserAddress,
    setDefaultUserAddress,
    initializePAYJP,
    ...toRefs(state),
  }
}

export type ProfileStore = ReturnType<typeof useProfileStore>
