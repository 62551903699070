import {API_V1} from '@/services/api.v1/common/api-service'

class YoutubeServiceV1 {
  getAuthURL() {
    return API_V1.get('/users/me/sns/youtube').then((result) => {
      return result.data
    })
  }
}

export const youtubeService = new YoutubeServiceV1()
