import {ApiV1Service} from './common/api-service'
import {Prefecture} from '@/models/master/prefecture'
import {VoucherCategory} from '@/models/master/voucherCategory'
import {VoucherTag} from '@/models/master/voucherTag'

class MasterServiceV1 extends ApiV1Service {
  fetchPrefectures() {
    return this.getList(Prefecture, '/master/prefectures')
  }

  fetchVoucherCategories() {
    return this.getList(VoucherCategory, '/master/voucherCategories')
  }

  fetchVoucherTags() {
    return this.getList(VoucherTag, '/master/voucherTags')
  }

  fetchPopularTags(limit: number) {
    return this.getList(VoucherTag, '/master/popularTags', {limit: limit})
  }
}

export const masterServiceV1 = new MasterServiceV1()
