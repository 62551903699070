
import {defineComponent} from 'vue'

export default defineComponent({
  components: {},
  props: {},
  setup(props) {
    return {}
  },
})
