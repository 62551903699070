import {ApiApplicationError} from './api-application-error'
import {ApiNetworkError} from './api-network-error'
import {ApiSystemError} from './api-system-error'

export * from './api-application-error'
export * from './api-network-error'
export * from './api-system-error'

export type ApiError = ApiApplicationError | ApiNetworkError | ApiSystemError

export function isApiError(error: unknown): error is ApiError {
  return (
    error instanceof ApiApplicationError ||
    error instanceof ApiNetworkError ||
    error instanceof ApiSystemError
  )
}
