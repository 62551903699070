import {AxiosError} from 'axios'
import {ApiApplicationErrorMessages} from './api-application-error-messages'
import {ErrorCode} from '@/config/errors'

export type ApplicationErrorResponse = {
  errorCode: number
  errorMessage: string
}

export class ApiApplicationError extends Error {
  constructor(
    readonly errorCode: number,
    readonly errorMessage: string,
    cause?: Error,
  ) {
    super(errorMessage, {cause})
    this.name = 'ApiApplicationError'
  }

  public static isApplicationErrorAxiosResponse(
    e: AxiosError,
  ): e is AxiosError<ApplicationErrorResponse> {
    const data = e.response?.data as any | undefined
    const errorCode = data?.errorCode
    const errorMessage = data?.errorMessage

    return typeof errorCode === 'number' && typeof errorMessage === 'string'
  }

  public static fromAxiosError(
    e: AxiosError<ApplicationErrorResponse>,
  ): ApiApplicationError {
    const errorCode = e.response?.data?.errorCode
    const errorMessage = e.response?.data?.errorMessage

    if (errorCode === undefined || errorMessage === undefined) {
      throw new Error('ApiApplicationError: invalid error response')
    }

    return new ApiApplicationError(
      errorCode,
      ApiApplicationErrorMessages[errorCode as unknown as ErrorCode] ??
        errorMessage,
      e,
    )
  }
}
