
import {useVoucherReserveStore} from '@/stores/voucher/voucher-reserve-store'
import {computed, defineComponent, onBeforeMount, onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ymd, ymdhm} from '@/utils/date-utils'
import {SuffixDiscountValueLabels} from '@/config/label'
import {ReservePaymentStatues} from '@/config/enum'
import {useProfileStore} from '@/stores/profile-store'
import {UserAddress, UserPaymentMethod} from '@/models/user' // eslint-disable-line no-unused-vars
import {CheckIcon, PencilSquareIcon} from '@heroicons/vue/24/outline'
import {showConfirmDialog} from 'vant'
import {useToastStore} from '@/stores/toast-store'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    CheckIcon,
    PencilSquareIcon,
  },
  props: {},
  setup() {
    const {
      fetchVoucherReserve,
      voucherReserve,
      payVoucherReserveInfo,
      payVoucherReserve,
      resetVoucherReserveStore,
    } = useVoucherReserveStore()
    const {
      userPaymentMethods,
      fetchUserPaymentMethods,
      userAddresses,
      fetchUserAddresses,
    } = useProfileStore()
    const {withLoading} = useToastStore()

    const isShowPaymentMethods = ref(false)
    const isShowAddresses = ref(false)

    const router = useRouter()
    const route = useRoute()

    const voucherReserveId = Number(route.params.voucherReserveId as string)

    onBeforeMount(async () => {
      await resetVoucherReserveStore()
    })

    onMounted(async () => {
      withLoading(async () => {
        await Promise.all([
          fetchVoucherReserve(voucherReserveId),
          fetchUserAddresses(),
          fetchUserPaymentMethods(),
        ])

        if (!payVoucherReserveInfo.value.voucherReserveId) {
          const defaultAddress = userAddresses.value.find((e) => e.isDefault)
          const defaultPaymentMethod = userPaymentMethods.value.find(
            (e) => e.isDefault,
          )

          payVoucherReserveInfo.value = {
            voucherReserveId,
            address: defaultAddress,
            paymentMethod: defaultPaymentMethod,
          }
        }
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const activeAddressId = computed(() => {
      return payVoucherReserveInfo.value.address?.addressId
    })
    const activePaymentMethodId = computed(() => {
      return payVoucherReserveInfo.value.paymentMethod?.paymentMethodId
    })

    const goVoucherDetail = () => {
      router.push({
        name: 'VoucherDetail',
        params: {
          voucherId: voucherReserve.value.voucher?.voucherId.toString(),
        },
      })
    }

    const openUserPaymentMethodsPopup = () => {
      isShowPaymentMethods.value = true
    }

    const openUserAddressesPopup = () => {
      isShowAddresses.value = true
    }

    const setAddress = (address: UserAddress) => {
      payVoucherReserveInfo.value.address = address
      isShowAddresses.value = false
    }

    const setPaymentMethod = (paymentMethod: UserPaymentMethod) => {
      payVoucherReserveInfo.value.paymentMethod = paymentMethod
      isShowPaymentMethods.value = false
    }

    const goAddressList = () => {
      router.push({name: 'Address'})
    }

    const goPaymentMethodList = () => {
      router.push({name: 'Payment'})
    }

    const isValid = computed(() => {
      if (voucherReserve.value.influencerAddressId) {
        return payVoucherReserveInfo.value.paymentMethod
      }
      return (
        payVoucherReserveInfo.value.address &&
        payVoucherReserveInfo.value.paymentMethod
      )
    })

    const onSubmit = () => {
      showConfirmDialog({
        title: '決済しますか？',
        message: 'この情報で決済を行ないます。',
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
      })
        .then(() => {
          return withLoading(async () => {
            await payVoucherReserve()
          }).catch((e) => {
            if (!isApiError(e)) {
              throw e
            }
          })
        })
        .catch(() => {})
    }

    return {
      voucherReserve,
      goVoucherDetail,
      ymd,
      ymdhm,
      SuffixDiscountValueLabels,
      userPaymentMethods,
      userAddresses,
      payVoucherReserveInfo,
      isShowPaymentMethods,
      isShowAddresses,
      openUserPaymentMethodsPopup,
      openUserAddressesPopup,
      setAddress,
      setPaymentMethod,
      goAddressList,
      goPaymentMethodList,
      activeAddressId,
      activePaymentMethodId,
      onSubmit,
      ReservePaymentStatues,
      isValid,
    }
  },
})
