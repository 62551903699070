export const MenuItemIds = {
  Home: 1,
  Favorite: 2,
  Reserve: 3,
  Notification: 4,
  Profile: 5,
  Browse: 6,
}
export type MenuItemId = (typeof MenuItemIds)[keyof typeof MenuItemIds]

export const MenuItemPaths = {
  [MenuItemIds.Home]: '/',
  [MenuItemIds.Favorite]: '/favorite',
  [MenuItemIds.Reserve]: '/reserve',
  [MenuItemIds.Notification]: '/notification',
  [MenuItemIds.Profile]: '/profile',
  [MenuItemIds.Browse]: '/browse',
}

export const ProviderIds = {
  EmailAndPassword: 1,
  Line: 2,
  Instagram: 3,
  Youtube: 4,
  Twitter: 5,
}
export type ProviderId = (typeof ProviderIds)[keyof typeof ProviderIds]

export const GenderIds = {
  male: 1,
  female: 2,
  others: 3,
}
export type GenderId = (typeof GenderIds)[keyof typeof GenderIds]

export const ToastTypeIds = {
  Success: 1,
  Error: 2,
}
export const ToastTypes = {
  [ToastTypeIds.Success]: 'success',
  [ToastTypeIds.Error]: 'error',
}
export type ToastType = (typeof ToastTypes)[keyof typeof ToastTypes]

export const SnsAuthenticationStatuses = {
  NotStarted: 1,
  InProgress: 2,
  Done: 3,
  Failed: 4,
}
export type SnsAuthenticationStatus =
  (typeof SnsAuthenticationStatuses)[keyof typeof SnsAuthenticationStatuses]

export const VoucherTypes = {
  Experience: 1,
  Product: 2,
}
export type VoucherType = (typeof VoucherTypes)[keyof typeof VoucherTypes]

export const DiscountTypes = {
  Amount: 1,
  Rate: 2,
  Free: 3,
  NoDiscount: 4,
}
export type DiscountType = (typeof DiscountTypes)[keyof typeof DiscountTypes]

export const PaymentMethods = {
  CreditCard: 1,
  Cash: 2,
}
export type PaymentMethod = (typeof PaymentMethods)[keyof typeof PaymentMethods]

// 1: "作成, コンシェルジュ確認待ち",
// 2: "支払い待ち",
// 3: "確定, 予約完了",
// 4: "使用済み, 利用済み",
// 5: "キャンセル"
export const ReserveStatuses = {
  Created: 1,
  WaitForPayment: 2,
  Diceded: 3,
  Done: 4,
  Cancel: 5,
}

export type ReserveStatus =
  (typeof ReserveStatuses)[keyof typeof ReserveStatuses]

// 1: "申請中",
// 2: "予約確定",
// 3: "利用済み",
// 4: "キャンセル",

export const ReserveTabItemIds = {
  Applied: 1,
  Reserved: 2,
  Used: 3,
  // Canceled: 4,
}

export const ReserveTabStatuses = {
  [ReserveTabItemIds.Applied]: [
    ReserveStatuses.Created,
    ReserveStatuses.WaitForPayment,
  ],
  [ReserveTabItemIds.Reserved]: [ReserveStatuses.Diceded],
  [ReserveTabItemIds.Used]: [ReserveStatuses.Done],
  // [ReserveTabItemIds.Canceled]: [ReserveStatuses.Cancel],
}

// 1: "作成",
// 2: "支払い済み",
// 3: "キャンセル",
export const ReservePaymentStatues = {
  Created: 1,
  Paid: 2,
  Canceled: 3,
}
