import {Campaign, FetchCampaignRequest} from '@/models/campaign'
import {ApiV1Service} from './common/api-service'

class CampaignServiceV1 extends ApiV1Service {
  fetchCampaignDetail(campaignId: number) {
    return this.get(Campaign, `/campaigns/${campaignId}`)
  }

  fetchCampaigns(request: FetchCampaignRequest) {
    return this.getPaginatedList(Campaign, `/campaigns`, request)
  }
}

export const campaignService = new CampaignServiceV1()
