
import {useUserSnsStore} from '@/stores/user-sns-store'
import {defineComponent, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useSessionLiffStore} from '@/stores/session-liff-store'
import {useProfileStore} from '@/stores/profile-store'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const {registUserSns} = useUserSnsStore()
    const {fetchUserProfile, userProfile} = useProfileStore()
    const {sendTextMessages, isSendMessage} = useSessionLiffStore()

    const router = useRouter()
    const route = useRoute()
    const goHome = () => {
      router.push({name: 'Home'})
    }

    const code = route.query.code as string
    const providerId = Number(route.params.providerId as string)

    onMounted(async () => {
      let registedUserSns
      try {
        registedUserSns = await registUserSns(code, providerId)
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
        return
      }

      let userNameOrAccount = ''
      try {
        await fetchUserProfile()
        userNameOrAccount = userProfile.value.nickName
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }

      if ('accountId' in registedUserSns) {
        userNameOrAccount = registedUserSns['accountId'] as string
      }
      await sendTextMessages([`【SNS審査申込:${userNameOrAccount}】`])
    })

    return {goHome, isSendMessage, userProfile}
  },
})
