
import {defineComponent, ref, computed, onMounted} from 'vue'
import {
  AdjustmentsHorizontalIcon,
  ChevronUpDownIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import {useMasterStore} from '@/stores/master-store'
import {useVoucherStore} from '@/stores/voucher/voucher-store'
import {useRoute, useRouter} from 'vue-router'
import {VoucherFilter} from '@/models/voucher-filter'

export default defineComponent({
  components: {
    AdjustmentsHorizontalIcon,
    ChevronUpDownIcon,
    CheckIcon,
    XMarkIcon,
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  setup() {
    const {
      voucherTags,
      fetchVoucherTags,
      voucherCategories,
      fetchVoucherCategories,
    } = useMasterStore()
    const {saveFilters, filters} = useVoucherStore()

    const router = useRouter()
    const route = useRoute()

    const isShowFilterEditor = ref(false)

    const openFilterEditor = () => {
      isShowFilterEditor.value = true
    }

    const query = ref('')

    const selectedFilters = ref<VoucherFilter>(new VoucherFilter())
    const filteredTags = computed(() =>
      query.value === ''
        ? voucherTags.value
        : voucherTags.value.filter((tag) => {
            return tag.name.toLowerCase().includes(query.value.toLowerCase())
          }),
    )

    const removeSelectedTag = (name: string) => {
      selectedFilters.value.tags = selectedFilters.value.tags.filter((tag) => {
        return tag.name.toLowerCase() !== name.toLowerCase()
      })
    }

    const saveFilterTags = () => {
      saveFilters(selectedFilters.value)
      isShowFilterEditor.value = false

      const categoryQuery = selectedFilters.value.category?.voucherCategoryId

      const tagQuery = selectedFilters.value.tags
        .map((e) => e.voucherTagId)
        .join(',')

      const queryParams = {}
      if (categoryQuery) {
        Object.assign(queryParams, {category: categoryQuery})
      }
      if (tagQuery) {
        Object.assign(queryParams, {tag: tagQuery})
      }
      router.push({
        path: route.fullPath,
        query: queryParams,
      })
    }

    const getFilterByPath = () => {
      const tagQuery = route.query.tag as string
      const categoryQuery = route.query.category as string

      if (tagQuery) {
        const tags = tagQuery.split(',')
        const selectedTagsByPath = voucherTags.value.filter((e) => {
          return tags.includes(String(e.voucherTagId))
        })

        selectedFilters.value.tags = selectedTagsByPath
      }

      if (categoryQuery) {
        const selectedCategoryByPath = voucherCategories.value.find(
          (e) => Number(e.voucherCategoryId) === Number(categoryQuery),
        )
        selectedFilters.value.category = selectedCategoryByPath
      }

      saveFilters(selectedFilters.value)
    }

    const discardChanges = () => {
      getFilterByPath()
    }

    const resetCategory = () => {
      selectedFilters.value.category = undefined
    }

    onMounted(async () => {
      await Promise.all([fetchVoucherTags(), fetchVoucherCategories()])

      getFilterByPath()
    })

    return {
      isShowFilterEditor,
      openFilterEditor,
      query,
      selectedFilters,
      filteredTags,
      filters,
      removeSelectedTag,
      saveFilterTags,
      discardChanges,
      voucherCategories,
      resetCategory,
    }
  },
})
