import {Empty} from '@/models/common/empty'
import {PaginationRequest} from '@/models/common/pagination'
import {VoucherSummary} from '@/models/voucher'
import {ApiV1Service} from './common/api-service'

class UserFavoriteVoucherServiceV1 extends ApiV1Service {
  fetchUserFavoriteVoucehrs(pagination: PaginationRequest) {
    return this.getPaginatedList(VoucherSummary, `/favorites`, pagination)
  }

  addFavoriteVoucher(voucherId: number) {
    return this.put(Empty, `/vouchers/${voucherId}/favorite`)
  }
}

export const userFavoriterVoucherService = new UserFavoriteVoucherServiceV1()
