
import {defineComponent} from 'vue'
import {useRouter} from 'vue-router'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const router = useRouter()
    const goNextPage = () => {
      router.push({path: '/'})
    }
    return {
      goNextPage,
    }
  },
})
