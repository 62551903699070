import {SignupBaseParams, SignupRequest} from '@/models/account'
import {accountService} from '@/services/api.v1/account-service'
import {reactive, toRefs} from 'vue'
import {useSessionStore} from './session-store'
import {ApiOptions} from '@/services/api.v1/common/api-service'

type State = {
  signupParams: SignupBaseParams
  isAfterSignup: boolean
}

const state = reactive<State>({
  signupParams: {
    nickName: '',
    genderId: null,
    prefectureId: null,
  },
  isAfterSignup: false,
})

export function useSignupStore() {
  const signup = async (
    params: {signupToken: string; providerId: number},
    options?: ApiOptions,
  ) => {
    const req: SignupRequest = {...params, ...state.signupParams}
    const res = await accountService.signup(req, options)

    const {setAuthInfo} = useSessionStore()
    setAuthInfo(res)
  }

  const setIsAfterSignup = (data: boolean) => {
    state.isAfterSignup = data
  }
  return {
    signup,
    setIsAfterSignup,
    ...toRefs(state),
  }
}

export type SignupStore = ReturnType<typeof useSignupStore>
