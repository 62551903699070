
import {defineComponent, ref, onMounted, computed} from 'vue'

import SProfileSnsCell from './SProfileSnsCell.vue'

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogTitle,
  DialogOverlay,
  DialogDescription,
} from '@headlessui/vue'
import {useUserSnsStore} from '@/stores/user-sns-store'
import {useProfileStore} from '@/stores/profile-store'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    Dialog,
    DialogTitle,
    TransitionRoot,
    TransitionChild,
    DialogOverlay,
    DialogDescription,
    SProfileSnsCell,
  },
  setup() {
    const {
      isSnsAuthenticated,
      instagramAuthentication,
      twitterAuthentication,
      youtubeAuthentication,
    } = useUserSnsStore()

    const {fetchUserProfile, userProfile} = useProfileStore()

    const isOpen = ref(!isSnsAuthenticated())

    onMounted(async () => {
      try {
        fetchUserProfile()
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    const nickName = computed(() => {
      const MAX_LENGTH = 20
      return userProfile.value.nickName.length > MAX_LENGTH
        ? `${userProfile.value.nickName.substring(0, MAX_LENGTH)}...`
        : userProfile.value.nickName
    })

    return {
      isOpen,
      instagramAuthentication,
      twitterAuthentication,
      youtubeAuthentication,
      userProfile,
      nickName,
    }
  },
})
