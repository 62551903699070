
import {defineComponent, onMounted} from 'vue'
import {useSignupStore} from '../../stores/signup-store'
import {GenderIds} from '@/config/enum'
import {GenderLabels} from '@/config/label'
import {useMasterStore} from '@/stores/master-store'
import {Form, Field, ErrorMessage} from 'vee-validate'
import {useRoute, useRouter} from 'vue-router'
import {useToastStore} from '@/stores/toast-store'
import {useGlobalStore} from '@/stores/global-store'
import {
  ApiApplicationError,
  isApiError,
} from '@/services/api.v1/common/api-service'
import {ErrorCodes} from '@/config/errors'

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {},
  setup(props) {
    const {signupParams, signup, setIsAfterSignup} = useSignupStore()
    const {prefectures, fetchPrefectures} = useMasterStore()

    const route = useRoute()
    const router = useRouter()

    const genderItems = Object.values(GenderIds).map((e) => {
      return {
        id: e,
        name: GenderLabels[e],
      }
    })

    const goPrivacy = () => {
      router.push({name: 'Privacy'})
    }

    const goTerms = () => {
      router.push({name: 'Terms'})
    }

    onMounted(async () => {
      try {
        // 選択肢の初期化
        await fetchPrefectures()
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    const {showSuccessToast, showErrorToast} = useToastStore()
    const {isLoading, startLoading, endLoading} = useGlobalStore()

    const onSubmit = async () => {
      const signupToken = route.query.token as string
      const providerId = Number(route.query.providerId as string)
      try {
        startLoading()
        await signup(
          {signupToken, providerId},
          {
            preventShowApplicationError: true,
          },
        )
      } catch (e) {
        if (e instanceof ApiApplicationError) {
          const errorCode = e.errorCode
          if (errorCode === ErrorCodes.ErrorCodeUserAlreadyExists) {
            showErrorToast('すでにアカウントが存在しています。')
          }
          if (errorCode === ErrorCodes.ErrorCodeInvalidProvider) {
            showErrorToast('新規登録に失敗しました。')
          }
        }
        throw e
      } finally {
        endLoading()
      }

      setIsAfterSignup(true)
      showSuccessToast('新規登録が完了しました。')
      router.push({path: '/'})
    }

    return {
      prefectures,
      genderItems,
      GenderLabels,
      signupParams,
      isLoading,
      onSubmit,
      goPrivacy,
      goTerms,
    }
  },
})
