
import {defineComponent, onBeforeMount, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useShopStore} from '@/stores/shop/shop-store'
import {SuffixDiscountValueLabels} from '@/config/label'
import {DiscountTypes} from '@/config/enum'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const route = useRoute()
    const router = useRouter()
    const {fetchShop, fetchShopVouchers, shop, vouchers, resetShopStore} =
      useShopStore()

    const shopId = Number(route.params.shopId as string)

    onBeforeMount(async () => {
      await resetShopStore()
    })

    onMounted(async () => {
      await Promise.all([fetchShop(shopId), fetchShopVouchers(shopId)]).catch(
        (e) => {
          if (!isApiError(e)) {
            throw e
          }
        },
      )
      document.title = shop.value.name
    })

    const goVoucherDetail = (voucherId: number) => {
      router.push({
        name: 'VoucherDetail',
        params: {voucherId: voucherId},
      })
    }

    return {
      shop,
      vouchers,
      SuffixDiscountValueLabels,
      DiscountTypes,
      goVoucherDetail,
    }
  },
})
