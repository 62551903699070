import {
  ProviderId,
  SnsAuthenticationStatus,
  SnsAuthenticationStatuses,
} from '@/config/enum'

export class UserSns {
  providerId!: ProviderId
  status!: SnsAuthenticationStatus

  defaultUserSns(providerId: ProviderId) {
    this.providerId = providerId
    this.status = SnsAuthenticationStatuses.NotStarted

    return this
  }

  dummyData(providerId: ProviderId) {
    this.providerId = providerId
    this.status = SnsAuthenticationStatuses.Done

    return this
  }

  get isNotStarted() {
    return this.status === SnsAuthenticationStatuses.NotStarted
  }

  get isInProgress() {
    return this.status === SnsAuthenticationStatuses.InProgress
  }

  get isFailed() {
    return this.status === SnsAuthenticationStatuses.Failed
  }

  get isDone() {
    return this.status === SnsAuthenticationStatuses.Done
  }
}

export type RegistUserSnsRequest = {
  code: string
  providerId: number
}
