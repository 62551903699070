import {
  FetchVoucherReservesByReserveStatusIdRequest,
  VerifyVoucherReserveRequest,
  VoucherReserveDetail,
  VoucherReserveSummary,
  PayVoucherReserveInfo,
  PayVoucherReserveRequest,
} from '@/models/voucher-reserve'
import {
  ApiOptions,
  DefaultPaginatedList,
  PaginatedList,
} from '@/services/api.v1/common/api-service'
import {voucherReserveService} from '@/services/api.v1/voucher-reserve-service'
import {reactive, toRefs} from 'vue'

type State = {
  voucherReserves: PaginatedList<VoucherReserveSummary>
  voucherReserve: VoucherReserveDetail

  payVoucherReserveInfo: PayVoucherReserveInfo
}

const initialState = () => {
  return {
    voucherReserves: DefaultPaginatedList,
    voucherReserve: new VoucherReserveDetail(),

    payVoucherReserveInfo: new PayVoucherReserveInfo(),
  }
}

const state = reactive<State>(initialState())

export function useVoucherReserveStore() {
  const resetVoucherReserveStore = async () => {
    Object.assign(state, initialState())
  }
  const fetchVoucherReserve = async (voucherReserveId: number) => {
    const res =
      await voucherReserveService.fetchVoucherReserve(voucherReserveId)
    state.voucherReserve = res
  }
  return {
    async createVoucherReserve(voucherId: number, options?: ApiOptions) {
      const res = await voucherReserveService.createVoucherReserve(
        voucherId,
        options,
      )
      state.voucherReserve = res
    },
    async fetchVoucherReservesByReserveStatusId(
      request: FetchVoucherReservesByReserveStatusIdRequest,
    ) {
      const res =
        await voucherReserveService.fetchVoucherReservesByReserveStatusId(
          request,
        )
      if (request.offset !== 0) {
        const currentData = state.voucherReserves.data

        state.voucherReserves.data = [...currentData, ...res.data]
        state.voucherReserves.isFinal = res.isFinal
        state.voucherReserves.totalCount = res.totalCount
        return
      }

      state.voucherReserves = res
    },
    fetchVoucherReserve,
    async fetchVoucherReserveWithReceipt(voucherReserveId: number) {
      const res =
        await voucherReserveService.fetchVoucherReserveWithReceipt(
          voucherReserveId,
        )
      state.voucherReserve = res
    },
    async verifyVoucherReserve(
      request: VerifyVoucherReserveRequest,
      options?: ApiOptions,
    ) {
      await voucherReserveService.verifyVoucherReserve(request, options)
      const res = await voucherReserveService.fetchVoucherReserveWithReceipt(
        request.voucherReserveId,
      )
      state.voucherReserve = res
    },

    async payVoucherReserve() {
      const request: PayVoucherReserveRequest = {
        voucherReserveId: state.payVoucherReserveInfo.voucherReserveId,
        addressId: state.payVoucherReserveInfo.address?.addressId,
        paymentMethodId:
          state.payVoucherReserveInfo.paymentMethod?.paymentMethodId!,
      }
      await voucherReserveService.payVoucherReserve(request)
      await fetchVoucherReserve(request.voucherReserveId)
    },

    resetVoucherReserveStore,
    ...toRefs(state),
  }
}

export type VoucherReserveStore = ReturnType<typeof useVoucherReserveStore>
