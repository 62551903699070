import axios, {AxiosError} from 'axios'
import {localStorageService} from '@/services/local-storage-service'
import {plainToClass} from 'class-transformer'
import {ArrayLikeList} from '@/models/common/pagination'
import {useToastStore} from '@/stores/toast-store'
import {ApiApplicationError, ApiNetworkError, ApiSystemError} from './errors'

const baseURL = process.env.VUE_APP_API_V1_ENDPOINT
if (baseURL === '') {
  throw new Error('api endpoint not configured.')
}

const {showErrorToast} = useToastStore()

export const API_V1 = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {},
})

export function setAPIErrorHandlerV1(handler: (error: AxiosError) => void) {
  API_V1.interceptors.response.use(
    (res) => {
      return res
    },
    (error) => {
      handler(error)
      return Promise.reject(error)
    },
  )
}

export type ApiOptions = {
  preventShowApplicationError?: boolean
}

export const setAuthInfo = function () {
  const headers: any = {}
  const token = localStorageService.accessToken

  if (token !== '') {
    const authorization = `Bearer ${token}`
    headers['Authorization'] = authorization
  }

  API_V1.defaults.headers = headers
}

API_V1.interceptors.request.use((req: any) => {
  if (!req.headers) {
    return {}
  }

  const token = localStorageService.accessToken
  if (token !== '') {
    const authorization = `Bearer ${token}`
    req.headers['Authorization'] = authorization
  }

  req.headers['Accept'] = 'application/json'
  req.headers['Content-Type'] = 'application/json'

  req.params = {...req.params}
  return req
})

export type PaginatedList<T> = {
  totalCount: number
  isFinal: boolean
  data: T[]
}

export const DefaultPaginatedList = {
  totalCount: 0,
  isFinal: false,
  data: [],
}

export class ApiV1Service {
  protected get<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T> {
    return API_V1.get<T>(url, {params})
      .then((res) => {
        return plainToClass(type, res.data)
      })
      .catch((e) => this.handlerError(e, options))
  }

  /**
   * GET Methodでリクエストする
   *
   * レスポンスボディが空のオブジェクトの場合、undefinedを返す
   *
   * @param type レスポンスボディの型
   * @param url リクエストするURL
   * @param params パラメーター
   * @returns
   */
  protected getOpt<T extends object>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T | undefined> {
    return API_V1.get<T>(url, {params})
      .then((res) => {
        // res.dataにAPIの戻り値のオブジェクトが格納されている
        return Object.keys(res.data).length === 0
          ? undefined
          : plainToClass(type, res.data)
      })
      .catch((e) => this.handlerError(e, options))
  }

  protected getList<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T[]> {
    return API_V1.get<ArrayLikeList<T>>(url, {params})
      .then((res) => {
        return res.data.data.map((e: T) => plainToClass(type, e))
      })
      .catch((e) => this.handlerError(e, options))
  }

  protected getPaginatedList<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<PaginatedList<T>> {
    return API_V1.get<PaginatedList<T>>(url, {params})
      .then((res) => {
        return {
          data: res.data.data.map((e: T) => plainToClass(type, e)),
          isFinal: res.data.isFinal,
          totalCount: res.data.totalCount ?? -1,
        }
      })
      .catch((e) => this.handlerError(e, options))
  }

  protected post<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T> {
    return API_V1.post<T>(url, params)
      .then((res) => plainToClass(type, res.data))
      .catch((e) => this.handlerError(e, options))
  }

  protected put<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T> {
    return API_V1.put<T>(url, params)
      .then((res) => plainToClass(type, res.data))
      .catch((e) => this.handlerError(e, options))
  }

  protected patch<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T> {
    return API_V1.patch<T>(url, params)
      .then((res) => plainToClass(type, res.data))
      .catch((e) => this.handlerError(e, options))
  }

  protected delete<T>(
    type: {new (): T},
    url: string,
    params: any = {},
    options?: ApiOptions,
  ): Promise<T> {
    return API_V1.delete<T>(url, {data: params})
      .then((res) => plainToClass(type, res.data))
      .catch((e) => this.handlerError(e, options))
  }

  private handlerError<T>(e: unknown, options: any): Promise<T> {
    if (!(e instanceof AxiosError)) {
      throw e
    }

    if (ApiNetworkError.isNetworkErrorAxiosResponse(e)) {
      const error = ApiNetworkError.fromAxiosError(e)
      showErrorToast(error.message)
      throw error
    }

    if (ApiSystemError.isSystemErrorAxiosResponse(e)) {
      const error = ApiSystemError.fromAxiosError(e)
      showErrorToast(error.message)
      throw error
    }

    if (ApiApplicationError.isApplicationErrorAxiosResponse(e)) {
      const error = ApiApplicationError.fromAxiosError(e)
      if (!options?.preventShowApplicationError) {
        showErrorToast(error.message)
      }
      throw error
    }

    console.warn('unknown AxiosError', e)

    throw e
  }
}
