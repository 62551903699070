import {Banner} from '@/models/banner'
import {bannerService} from '@/services/api.v1/banner-service'
import {reactive, toRefs} from 'vue'

type State = {
  banners: Banner[]
}

const state = reactive<State>({
  banners: [],
})

export function useBannerStore() {
  const fetchBanners = async () => {
    const banners = await bannerService.fetchBanners()
    state.banners = banners
  }
  return {
    fetchBanners,
    ...toRefs(state),
  }
}

export type BannerStore = ReturnType<typeof useBannerStore>
