import {defineRule} from 'vee-validate'

defineRule('required', (value: string) => {
  if (!value || !value.length) {
    return 'この項目は必須です。'
  }
  return true
})

defineRule('requiredNullable', (value: any) => {
  if (!value || null) {
    return 'この項目は必須です。'
  }
  return true
})

defineRule('email', (value: string) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }
  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return 'This field must be a valid email'
  }
  return true
})
