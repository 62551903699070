import {AwsRum, AwsRumConfig} from 'aws-rum-web'

let awsRum: AwsRum | null = null
try {
  const config: AwsRumConfig = JSON.parse(
    process.env.VUE_APP_AWS_RUM_APPLICATION_CONFIG ?? '',
  )

  const APPLICATION_ID = process.env.VUE_APP_AWS_RUM_APPLICATION_ID
  const APPLICATION_VERSION: string =
    process.env.VUE_APP_AWS_RUM_APPLICATION_VERSION ?? ''
  const APPLICATION_REGION: string =
    process.env.VUE_APP_AWS_RUM_APPLICATION_REGION ?? ''

  if (APPLICATION_ID) {
    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    )
  }
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export function recordError(error: unknown): void {
  if (awsRum) {
    awsRum.recordError(error)
  }
}
