import {
  DiscountTypes,
  GenderIds,
  MenuItemIds,
  PaymentMethods,
  ReserveTabItemIds,
  VoucherTypes,
} from './enum'

export const MenuItemLabels = {
  [MenuItemIds.Home]: 'ホーム',
  [MenuItemIds.Favorite]: 'お気に入り',
  [MenuItemIds.Reserve]: '特別優待管理',
  [MenuItemIds.Notification]: 'お知らせ',
  [MenuItemIds.Profile]: 'マイページ',
}
export type MenuItemLabel = (typeof MenuItemLabels)[keyof typeof MenuItemLabels]

export const ReserveTabItemLabels = {
  [ReserveTabItemIds.Applied]: '申請中',
  [ReserveTabItemIds.Reserved]: '利用可能',
  [ReserveTabItemIds.Used]: '利用済み',
  // [ReserveTabItemIds.Canceled]: "キャンセル"
}

export const GenderLabels = {
  [GenderIds.male]: '男性',
  [GenderIds.female]: '女性',
  [GenderIds.others]: 'その他',
}
export type GenderLabel = (typeof GenderLabels)[keyof typeof GenderLabels]

export const SuffixDiscountValueLabels = {
  [DiscountTypes.Amount]: '円OFF',
  [DiscountTypes.NoDiscount]: '',
  [DiscountTypes.Rate]: '％OFF',
  [DiscountTypes.Free]: '',
}

export const VoucherReserveCodeMessageLabels = {
  [VoucherTypes.Experience]: '優待予約コード',
  [VoucherTypes.Product]: '優待注文コード',
}

export const VoucherReserveMessageByCategory = [
  [],
  [
    // 飲食店
    '{{nickName}}様、優待利用をご依頼いただきありがとうございます✨',
    '',
    '予約手配のため、以下①〜③の情報を教えていただけますでしょうか😊',
    '※金額確認後に、予約するかご判断頂けますので、ご安心くださいませ。',
    '（このままチャットにご返信ください！）',
    '',
    '① ご希望の日時　',
    '　記載例）○月○日　○時希望　',
    '② 人数',
    '③ 本名（フルネーム）',
    '',
    'またご予約時にご連絡先が必要な為、マイページ「ユーザー情報」と「住所管理」の内容が登録されているかご確認ください😊',
    '',
    'ご返信頂けましたら、予約の確認を進めさせて頂きます🙇‍♂️',
  ],
  [], // お取り寄せグルメ
  [], // 住まい暮らし
  [
    // サロンジム
    '{{nickName}}様、優待利用をご依頼いただきありがとうございます✨',
    '',
    '予約手配のため、以下①〜③の情報を教えていただけますでしょうか😊',
    '※金額確認後に、予約するかご判断頂けますので、ご安心くださいませ。',
    '（このままチャットにご返信ください！）',
    '',
    '① ご希望の日時　',
    '　記載例）○月○日　○時希望　',
    '② 人数',
    '③ 本名（フルネーム）',
    '',
    'またご予約時にご連絡先が必要な為、マイページ「ユーザー情報」と「住所管理」の内容が登録されているかご確認ください😊',
    '',
    'ご返信頂けましたら、予約の確認を進めさせて頂きます🙇‍♂️',
  ],
  [
    // 宿泊施設
    '{{nickName}}様、優待利用をご依頼いただきありがとうございます✨',
    '',
    '料金・空き状況確認のため、以下の情報を教えていただけますでしょうか😊',
    '※金額確認後に予約するかご判断頂けますので、ご安心くださいませ。',
    '（このままチャットにご返信ください！）',
    '',
    '①ご希望日程',
    '　記載例）○月○日から○泊希望　',
    '② 人数(大人◯人、子供◯人）',
    '③ 本名（フルネーム）',
    '④ご希望のお部屋　(お部屋選択の記載がある場合のみ)',
    '',
    'また予約時にご連絡先が必要な為、マイページ「ユーザー情報」と「住所管理」の内容が登録されているかご確認ください😊',
    '',
    'ご返信頂けましたら、予約の確認を進めさせて頂きます🙇‍♂️',
  ],
]

export const ExperienceUsageLabels =
  'こちらの特別優待のご利用はご予約が必要となります。\nご予約は下記ボタンよりコンシェルジュに依頼してください。\n\n日時の調整等は、コンシェルジュとLINEにて行なってください。なお、ご予約状況によってご希望に添えない場合がございます。あらかじめご了承ください。'

export const ExperiencePaymentLabels = {
  [PaymentMethods.CreditCard]:
    '代金の支払いは、ご登録のクレジットカードから事前決済をお願いいたします。詳しくはコンシェルジュからご案内させていただきます。',
  [PaymentMethods.Cash]:
    '代金の支払いは、直接現地にてお願いいたします。\n\nご予約が確定後「特別優待管理」の「利用可能」をタップしていただくと、当該特別優待が表示されますので、現地スタッフにお見せください。',
}

export const ProductUsageLabels =
  '下記ボタンよりコンシェルジュを通じてご購入いただくと、こちらの「特別優待」の条件が適用されます。\n\nこちらの商品はコンシェルジュにご注文依頼をいただいた後在庫を確認いたします。'

export const ProductPaymentLabels = {
  1: '代金のお支払いは、ご登録のクレジットカードからお願いいたします。こちらの商品はご注文依頼時に決済のお手続きをお願いしております。\n\n在庫がなかった場合は、決済を取り消させていただきます。',
  2: '代金のお支払いは、ご登録のクレジットカードからお願いいたします。\n\n在庫があった場合、決済のご案内をさせていただきますので、期限までに決済をお願いいたします。\n期限までにお支払いが確認できなかった場合は、ご購入を取り消させていただきます。',
}
