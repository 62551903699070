
import {useVoucherReserveStore} from '@/stores/voucher/voucher-reserve-store'
import {defineComponent, onBeforeMount, onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ymd, ymdhm} from '@/utils/date-utils'
import {CheckCircleIcon} from '@heroicons/vue/24/outline'
import {VerifyVoucherReserveRequest} from '@/models/voucher-reserve' // eslint-disable-line no-unused-vars
import {
  ApiApplicationError,
  isApiError,
} from '@/services/api.v1/common/api-service'
import {ErrorCodes} from '@/config/errors'
import {useToastStore} from '@/stores/toast-store'
import {
  DiscountTypes,
  PaymentMethods,
  ReserveStatuses,
  VoucherTypes,
} from '@/config/enum'
import {SuffixDiscountValueLabels} from '@/config/label'

export default defineComponent({
  components: {
    CheckCircleIcon,
  },
  props: {},
  setup() {
    const {
      voucherReserve,
      fetchVoucherReserveWithReceipt,
      verifyVoucherReserve,
      resetVoucherReserveStore,
    } = useVoucherReserveStore()
    const {withLoading, showErrorToast} = useToastStore()
    const route = useRoute()
    const router = useRouter()
    const voucherReserveId = Number(route.params.voucherReserveId as string)

    const showKeyboard = ref(false)
    const showVerifyPopup = ref(false)
    const inputValue = ref('')
    const isInvalidVerifyCodeError = ref(false)
    const isVerifySuccess = ref(false)

    const openVerifyPopup = () => {
      showVerifyPopup.value = true
    }

    const verifyReserve = async () => {
      try {
        const request: VerifyVoucherReserveRequest = {
          voucherReserveId: voucherReserve.value.reserveId!,
          verifyCode: inputValue.value,
        }
        await verifyVoucherReserve(request, {
          preventShowApplicationError: true,
        })
        isVerifySuccess.value = true
      } catch (e) {
        if (e instanceof ApiApplicationError) {
          if (e.errorCode === ErrorCodes.ErrorCodeInvalidVerifyCode) {
            isInvalidVerifyCodeError.value = true
          } else {
            closePopup()
            showErrorToast('認証コードの認証が正しく行えませんでした。')
          }
        }
        throw e
      }
    }

    onBeforeMount(async () => {
      await resetVoucherReserveStore()
    })

    onMounted(async () => {
      withLoading(async () => {
        await fetchVoucherReserveWithReceipt(voucherReserveId)
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const goVoucherDetail = () => {
      router.push({
        name: 'VoucherDetail',
        params: {
          voucherId: voucherReserve.value.voucher?.voucherId.toString(),
        },
      })
    }

    const resetInputValue = () => {
      inputValue.value = ''
      isInvalidVerifyCodeError.value = false
    }

    const closePopup = () => {
      showVerifyPopup.value = false
    }

    return {
      showVerifyPopup,
      showKeyboard,
      inputValue,
      verifyReserve,
      voucherReserve,
      goVoucherDetail,
      openVerifyPopup,
      ymd,
      ymdhm,
      isInvalidVerifyCodeError,
      isVerifySuccess,
      resetInputValue,
      closePopup,
      PaymentMethods,
      SuffixDiscountValueLabels,
      DiscountTypes,
      ReserveStatuses,
      VoucherTypes,
    }
  },
})
