import {ToastType, ToastTypeIds, ToastTypes} from '@/config/enum'
import {showLoadingToast} from 'vant'
import {reactive, toRefs} from 'vue'

type State = {
  isShowToast: boolean
  toastMessage: string
  toastType: ToastType

  isLoading: boolean
}

const state = reactive<State>({
  isShowToast: false,
  toastMessage: '',
  toastType: '',

  isLoading: false,
})

export function useToastStore() {
  const showSuccessToast = (message: string) => {
    state.isShowToast = true
    state.toastMessage = message
    state.toastType = ToastTypes[ToastTypeIds.Success]

    setTimeout(() => {
      state.isShowToast = false
    }, 2 * 1000)
  }
  const showErrorToast = (message: string) => {
    state.isShowToast = true
    state.toastMessage = message
    state.toastType = ToastTypes[ToastTypeIds.Error]

    setTimeout(() => {
      state.isShowToast = false
    }, 2 * 1000)
  }
  const withLoading = async (callback: () => Promise<void>) => {
    state.isLoading = true
    const toast = showLoadingToast({
      type: 'loading',
      duration: 0,
      loadingType: 'spinner',
    })

    try {
      await callback()
    } finally {
      state.isLoading = false
      toast?.close()
    }
  }
  return {
    showSuccessToast,
    showErrorToast,
    withLoading,
    ...toRefs(state),
  }
}

export type ToastStore = ReturnType<typeof useToastStore>
