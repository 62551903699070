
import {defineComponent} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {
  HomeIcon,
  UserIcon,
  HeartIcon,
  ShoppingCartIcon,
  MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline'
import {useGlobalStore} from '@/stores/global-store'
import {MenuItemLabels} from '@/config/label'
import {MenuItemIds, MenuItemPaths} from '@/config/enum'

export default defineComponent({
  components: {
    HomeIcon,
    UserIcon,
    HeartIcon,
    ShoppingCartIcon,
    MagnifyingGlassIcon,
  },
  setup() {
    const {currentMenuItemId, setCurrentMenuItemId} = useGlobalStore()

    const route = useRoute()
    const router = useRouter()

    const goBottomBarNavigation = (selectedId: number) => {
      router.push({
        path: MenuItemPaths[selectedId],
      })
      setCurrentMenuItemId(selectedId)
    }

    const getCurrentMenuByPath = () => {
      const id = Object.keys(MenuItemPaths).find(
        (e) => MenuItemPaths[Number(e)] === route.path,
      )
      setCurrentMenuItemId(Number(id))
    }
    getCurrentMenuByPath()

    return {
      currentMenuItemId,
      MenuItemLabels,
      MenuItemIds,
      goBottomBarNavigation,
    }
  },
})
