import {PaginationRequest} from './common/pagination'
import {VoucherSummary} from './voucher'

export class Campaign {
  campaignId?: number
  priority: number = 1
  title: string = ''
  subTitle: string = ''
  description: string = ''
  imageUrl: string = ''
  body: string = ''
  startDate!: Date
  endDate!: Date
  vouchers: VoucherSummary[] = []
}

export type FetchCampaignRequest = {} & PaginationRequest
