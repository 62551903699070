
import {useGlobalStore} from '@/stores/global-store'
import {useToastStore} from '@/stores/toast-store'
import {useVoucherStore} from '@/stores/voucher/voucher-store'
import {defineComponent, onMounted} from 'vue'
import SVoucherCard from '@/views/voucher/SVoucherCard.vue'
import SBottomBar from '@/components/SBottomBar.vue'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    SVoucherCard,
    SBottomBar,
  },
  props: {},
  setup() {
    const {fetchUserFavoriteVouchers, vouchers} = useVoucherStore()
    const {withLoading} = useToastStore()
    const {isLoading, startLoading, endLoading} = useGlobalStore()

    onMounted(async () => {
      await withLoading(async () => {
        await fetchUserFavoriteVouchers({offset: 0, limit: 5})
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const loadMoreVouchers = async () => {
      startLoading()
      await withLoading(async () => {
        await fetchUserFavoriteVouchers({
          offset: vouchers.value.data.length,
          limit: 5,
        })
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
      endLoading()
    }

    return {
      isLoading,
      vouchers,
      loadMoreVouchers,
    }
  },
})
