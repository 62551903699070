
import {VoucherReserveSummary} from '@/models/voucher-reserve' // eslint-disable-line no-unused-vars
import {defineComponent, PropType} from 'vue' // eslint-disable-line no-unused-vars
import {ArrowLongLeftIcon} from '@heroicons/vue/24/outline'
import {ymd, ymdhm} from '@/utils/date-utils'
import {useRouter} from 'vue-router'
import {ReserveStatuses, DiscountTypes, VoucherTypes} from '@/config/enum'
import {SuffixDiscountValueLabels} from '@/config/label'

export default defineComponent({
  components: {
    ArrowLongLeftIcon,
  },
  props: {
    reserve: {
      type: Object as PropType<VoucherReserveSummary>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()

    const goVoucherDetail = () => {
      router.push({
        name: 'VoucherDetail',
        params: {voucherId: props.reserve.voucher?.voucherId.toString()},
      })
    }

    const goReservePayment = () => {
      router.push({
        name: 'ReservePayment',
        params: {voucherReserveId: props.reserve.reserveId},
      })
    }

    const goReserveReciept = () => {
      router.push({
        name: 'ReserveReceipt',
        params: {voucherReserveId: props.reserve.reserveId},
      })
    }

    return {
      ymd,
      ymdhm,
      goVoucherDetail,
      ReserveStatuses,
      DiscountTypes,
      SuffixDiscountValueLabels,
      VoucherTypes,
      goReservePayment,
      goReserveReciept,
    }
  },
})
