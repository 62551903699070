import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_empty = _resolveComponent("van-empty")!
  const _component_SReserveCard = _resolveComponent("SReserveCard")!
  const _component_van_list = _resolveComponent("van-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_list, {
      loading: _ctx.isLoading,
      "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      finished: _ctx.voucherReserves.isFinal,
      "finished-text": "",
      "loading-text": "loading",
      class: "space-y-4",
      onLoad: _ctx.loadMoreVoucherReserves
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "finished", {}, () => [
          (!_ctx.voucherReserves.data.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_van_empty, { description: "表示できる特別優待はありません" })
              ]))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voucherReserves.data, (reserve) => {
          return (_openBlock(), _createElementBlock("div", {
            key: reserve.reserveId
          }, [
            _createVNode(_component_SReserveCard, { reserve: reserve }, null, 8, ["reserve"])
          ]))
        }), 128))
      ]),
      _: 3
    }, 8, ["loading", "finished", "onLoad"])
  ]))
}