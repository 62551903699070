import {Empty} from '@/models/common/empty'
import {
  CreateUserPaymentMethodRequest,
  UserAddress,
  UserDeleteRequest,
  UserPaymentMethod,
  UserProfile,
} from '@/models/user'
import {ApiV1Service} from './common/api-service'

class UserServiceV1 extends ApiV1Service {
  fetchUserProfile() {
    return this.get(UserProfile, '/users/me/profile')
  }

  updateUserProfile(userProfile: UserProfile) {
    return this.post(UserProfile, '/users/me/profile', userProfile)
  }

  deleteUser(req: UserDeleteRequest) {
    return this.post(Empty, '/users/me/delete', req)
  }

  createOrUpdateUserPaymentMethod(request: CreateUserPaymentMethodRequest) {
    return this.post(Empty, '/users/me/paymentMethods', request)
  }

  fetchUserPaymentMethods() {
    return this.getList(UserPaymentMethod, '/users/me/paymentMethods')
  }

  setDefaultUserPaymentMethod(userPaymentMethodId: string) {
    return this.patch(Empty, `/users/me/paymentMethods/${userPaymentMethodId}`)
  }

  deleteUserPaymentMethod(userPaymentMethodId: string) {
    return this.delete(Empty, `/users/me/paymentMethods/${userPaymentMethodId}`)
  }

  createOrUpdateUserAddress(request: UserAddress) {
    return this.post(Empty, '/users/me/addresses', request)
  }

  setDefaultUserAddress(userAddressId: number) {
    return this.patch(Empty, `/users/me/addresses/${userAddressId}`)
  }

  deleteUserAddress(userAddressId: number) {
    return this.delete(Empty, `/users/me/addresses/${userAddressId}`)
  }

  fetchUserAddresses() {
    return this.getList(UserAddress, '/users/me/addresses')
  }
}

export const userService = new UserServiceV1()
