
import {defineComponent, onMounted, ref, onBeforeUnmount} from 'vue'
import {CreateUserPaymentMethodRequest} from '@/models/user' // eslint-disable-line no-unused-vars
import {useProfileStore} from '@/stores/profile-store'
import {useToastStore} from '@/stores/toast-store'
import {CheckIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {showConfirmDialog} from 'vant'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    CheckIcon,
    XMarkIcon,
  },
  props: {},
  setup() {
    const showAddPaymentPopup = ref(false)
    const errorMessage = ref('')
    const isDefault = ref(false)

    const {
      createOrUpdateUserPaymentMethod,
      fetchUserPaymentMethods,
      setDefaultUserPaymentMethod,
      deleteUserPaymentMethod,
      userPaymentMethods,
      payjp,
      initializePAYJP,
    } = useProfileStore()
    const {showSuccessToast, withLoading} = useToastStore()

    onMounted(() => {
      withLoading(async () => {
        await fetchUserPaymentMethods()
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    initializePAYJP()

    // elementsを取得します。ページ内に複数フォーム用意する場合は複数取得ください
    const elements = payjp.value.elements()

    const style = {
      base: {
        color: '#000000',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        paddingTop: '8px',
        ':-webkit-autofill': {
          color: 'green',
        },
        '::placeholder': {
          color: '#A5B9BB',
        },
      },
      invalid: {
        fontWeight: 500,
      },
    }

    // element(入力フォームの単位)を生成します
    const numberElement = elements.create('cardNumber', {
      style: style,
    })

    const expiryElement = elements.create('cardExpiry', {
      style: style,
    })

    const cvcElement = elements.create('cardCvc', {
      style: style,
    })

    const openAddPaymentMethodPopup = () => {
      showAddPaymentPopup.value = true
    }

    const mountAddPaymentMethod = () => {
      // elementをDOM上に配置します
      numberElement.mount('#number-form')
      expiryElement.mount('#expiry-form')
      cvcElement.mount('#cvc-form')
    }

    const closeAddPaymentMethodPopup = () => {
      numberElement.unmount()
      expiryElement.unmount()
      cvcElement.unmount()

      showAddPaymentPopup.value = false
    }

    onBeforeUnmount(() => {
      closeAddPaymentMethodPopup()
    })

    const onSubmit = () => {
      withLoading(async () => {
        await payjp.value.createToken(numberElement).then(async (r: any) => {
          if (r.error) {
            errorMessage.value = r.error.message
            return
          }

          const request: CreateUserPaymentMethodRequest = {
            token: r.id,
            isDefault: isDefault.value,
          }

          await createOrUpdateUserPaymentMethod(request)
          showSuccessToast('クレジットカードを追加しました。')
          closeAddPaymentMethodPopup()
        })
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    }

    const deletePaymentMethod = (paymentMethodId: string) => {
      showConfirmDialog({
        title: '削除します',
        message: 'このクレジットカードを削除しますか？',
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
      })
        .then(() => {
          return withLoading(async () => {
            await deleteUserPaymentMethod(paymentMethodId)
          }).catch((e) => {
            if (!isApiError(e)) {
              throw e
            }
          })
        })
        .catch(() => {
          console.log('cancel')
        })
    }

    const setIsDefault = (paymentMethodId: string) => {
      showConfirmDialog({
        title: 'デフォルトに設定する',
        message: 'このクレジットカードをデフォルトに設定しますか？',
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
      })
        .then(() => {
          withLoading(async () => {
            await setDefaultUserPaymentMethod(paymentMethodId)
          }).catch((e) => {
            if (!isApiError(e)) {
              throw e
            }
          })
        })
        .catch(() => {
          console.log('cancel')
        })
    }

    return {
      showAddPaymentPopup,
      openAddPaymentMethodPopup,
      closeAddPaymentMethodPopup,
      mountAddPaymentMethod,
      onSubmit,
      errorMessage,
      userPaymentMethods,
      deletePaymentMethod,
      setIsDefault,
      isDefault,
    }
  },
})
