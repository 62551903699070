
import {defineComponent} from 'vue'
import SNotifications from '@/components/SNotifications.vue'
import {setAPIErrorHandlerV1} from './services/api.v1/common/api-service'
import {ErrorCodes} from './config/errors'
import {useSessionStore} from './stores/session-store'

export default defineComponent({
  components: {
    SNotifications,
  },
  props: {},
  setup() {
    const {clearAuthInfo, isLoadingForSignin} = useSessionStore()

    setAPIErrorHandlerV1(async (error) => {
      const errorCode = (error.response?.data as any)?.errorCode
      if (errorCode == ErrorCodes.ErrorCodeNotAuthenticated) {
        //セッション切れエラー
        clearAuthInfo()
      }
    })

    return {
      isLoadingForSignin,
    }
  },
})
