import {LineProfile} from '@/models/account'
import {reactive, toRefs} from 'vue'
import {sleep} from '@/utils/sleep'
import liff from '@line/liff'

type State = {
  isLiffInitialized: boolean
  isLineLogined: boolean
  lineProfile: LineProfile | null
  isFriend: boolean
  isValidClient: boolean
  isSendMessage: boolean
  token?: string
}

const state = reactive<State>({
  isLiffInitialized: false,
  isLineLogined: false,
  lineProfile: null,
  isFriend: false,
  isValidClient: false,
  isSendMessage: false,
  token: undefined,
})

export function useSessionLiffStore() {
  const getFriendship = async () => {
    await import('@line/liff').then(async (_liff: any) => {
      if (
        process.env.NODE_ENV === 'production' ||
        process.env.NODE_ENV === 'staging'
      ) {
        const res = await _liff.liff.getFriendship()
        state.isFriend = res.friendFlag
        return
      }

      state.isFriend = true
    })
  }

  const getAccessClient = async () => {
    const res = await import('@line/liff').then(async (_liff: any) => {
      if (
        process.env.NODE_ENV === 'production' ||
        process.env.NODE_ENV === 'staging'
      ) {
        return _liff.liff.isInClient()
      }
      return true
    })
    return res
  }

  const initializeLiff = async () => {
    await import('@line/liff').then(async (_liff: any) => {
      if (process.env.NODE_ENV === 'development') {
        state.lineProfile = {
          userId: 'tets',
          displayName: 'test',
          pictureUrl:
            'https://thumb.ac-illust.com/73/7387030e5a5600726e5309496353969a_t.jpeg',
        }
        return
      }
      await _liff.liff.init({liffId: process.env.VUE_APP_LIFF_ID})
      state.isLiffInitialized = true

      await getFriendship()
      await getAccessClient()

      if (
        !_liff.liff.isLoggedIn() &&
        (process.env.NODE_ENV === 'production' ||
          process.env.NODE_ENV === 'staging')
      ) {
        await _liff.liff.login({redirectUri: process.env.VUE_APP_URL})
      }

      state.lineProfile = _liff.liff.getProfile()
    })
  }

  const getLineIdToken = async () => {
    if (process.env.NODE_ENV === 'development') {
      return 'dummy'
    }
    if (state.token) {
      return state.token
    }
    const token = await import('@line/liff').then(async (_liff: any) => {
      if (!state.isLiffInitialized) {
        await initializeLiff()
      }

      return _liff.liff.getIDToken()
    })
    state.token = token
    return token
  }

  const lineLogin = () => {
    import('@line/liff').then(async (_liff: any) => {
      if (
        !_liff.liff.isLoggedIn() &&
        (process.env.NODE_ENV === 'production' ||
          process.env.NODE_ENV === 'staging')
      ) {
        await _liff.liff.login({redirectUri: process.env.VUE_APP_URL})
      }
      state.isLineLogined = true
    })
  }

  const sendTextMessages = async (messages: string[], numOfTry: number = 3) => {
    do {
      await liff
        .sendMessages(messages.map((text) => ({type: 'text', text: text})))
        .then(() => {
          state.isSendMessage = true
        })
        .catch(() => {
          state.isSendMessage = false
        })

      if (--numOfTry <= 0) {
        break
      }
      if (!state.isSendMessage) {
        await sleep(3000)
      }
    } while (!state.isSendMessage)
  }

  return {
    initializeLiff,
    lineLogin,
    getLineIdToken,
    getFriendship,
    getAccessClient,
    sendTextMessages,
    ...toRefs(state),
  }
}

export type SessionLiffStore = ReturnType<typeof useSessionLiffStore>
