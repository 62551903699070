import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  "aria-live": "assertive",
  class: "pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:items-start sm:p-6 z-10"
}
const _hoisted_2 = { class: "flex w-full flex-col items-center space-y-4 sm:items-end" }
const _hoisted_3 = {
  key: 0,
  class: "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
}
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = { class: "flex items-start" }
const _hoisted_6 = { class: "flex-shrink-0" }
const _hoisted_7 = { class: "ml-3 w-0 flex-1 pt-0.5 text-left" }
const _hoisted_8 = { class: "text-sm font-medium text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_ExclamationTriangleIcon = _resolveComponent("ExclamationTriangleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        "enter-active-class": "transform ease-out duration-300 transition",
        "enter-from-class": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
        "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
        "leave-active-class": "transition ease-in duration-100",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          (_ctx.isShowToast)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.toastType === _ctx.ToastTypes[_ctx.ToastTypeIds.Success])
                        ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
                            key: 0,
                            class: "h-6 w-6 text-green-400",
                            "aria-hidden": "true"
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.toastType === _ctx.ToastTypes[_ctx.ToastTypeIds.Error])
                        ? (_openBlock(), _createBlock(_component_ExclamationTriangleIcon, {
                            key: 1,
                            class: "h-6 w-6 text-red-400",
                            "aria-hidden": "true"
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.toastMessage), 1)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}