import {MenuItemId} from '@/config/enum'
import {reactive, toRefs} from 'vue'

type State = {
  currentMenuItemId: MenuItemId
  isLoading: boolean
}

const state = reactive<State>({
  currentMenuItemId: 1,
  isLoading: false,
})

export function useGlobalStore() {
  const setCurrentMenuItemId = (menuItemId: MenuItemId) => {
    state.currentMenuItemId = menuItemId
  }

  const startLoading = () => {
    state.isLoading = true
  }

  const endLoading = () => {
    state.isLoading = false
  }

  return {
    setCurrentMenuItemId,
    startLoading,
    endLoading,
    ...toRefs(state),
  }
}

export type GlobalStore = ReturnType<typeof useGlobalStore>
