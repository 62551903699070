import './initializers/vee-validate'
import 'vue3-carousel/dist/carousel.css'
import 'vant/lib/index.css'

import './utils/aws-rum'

import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import VueGtag from 'vue-gtag'

import {
  List,
  DatePicker,
  Popup,
  Swipe,
  SwipeItem,
  Empty,
  PasswordInput,
  NumberKeyboard,
  Dialog,
} from 'vant'

import '@/assets/styles/style.scss'
import 'vue3-carousel/dist/carousel.css'

const app = createApp(App)

app.use(List)
app.use(DatePicker)
app.use(Popup)
app.use(Swipe)
app.use(SwipeItem)
app.use(Empty)
app.use(PasswordInput)
app.use(NumberKeyboard)
app.use(Dialog)
// @ts-ignore
app.use(VueGtag, {
  config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID},
})

app.use(router)

app.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info)
}

app.mount('#app')
