
import {SuffixDiscountValueLabels} from '@/config/label'
import {useVoucherStore} from '@/stores/voucher/voucher-store'
import {computed, defineComponent, onBeforeMount, onMounted, ref} from 'vue'
import {useRoute} from 'vue-router'
import {ymd} from '@/utils/date-utils'
import {ChevronRightIcon} from '@heroicons/vue/24/outline'
import {useSessionLiffStore} from '@/stores/session-liff-store'
import {useVoucherReserveStore} from '@/stores/voucher/voucher-reserve-store'
import {useProfileStore} from '@/stores/profile-store'
import {
  VoucherTypes,
  DiscountTypes,
  PaymentMethods,
  ReserveStatuses,
} from '@/config/enum'
import {
  VoucherReserveCodeMessageLabels,
  VoucherReserveMessageByCategory,
  ExperienceUsageLabels,
  ProductUsageLabels,
  ExperiencePaymentLabels,
  ProductPaymentLabels,
} from '@/config/label'
import _ from 'lodash'
import router from '@/router/router'
import {HeartIcon} from '@heroicons/vue/20/solid'
import {useToastStore} from '@/stores/toast-store'
import {
  ApiApplicationError,
  isApiError,
} from '@/services/api.v1/common/api-service'
import {ErrorCodes} from '@/config/errors'

export default defineComponent({
  components: {
    ChevronRightIcon,
    HeartIcon,
  },
  props: {},
  setup() {
    const route = useRoute()
    const {fetchVoucherDetail, voucher, addFavoriteVoucher, resetVoucherStore} =
      useVoucherStore()
    const {showSuccessToast, withLoading, showErrorToast} = useToastStore()
    const {createVoucherReserve, voucherReserve} = useVoucherReserveStore()
    const {fetchUserProfile, userProfile} = useProfileStore()
    const {sendTextMessages, isSendMessage} = useSessionLiffStore()

    const voucherId = Number(route.params.voucherId as string)

    const expireDate = () => {
      if (voucher.value) {
        const startDate = new Date(voucher.value.startDate)
        const endDate = new Date(voucher.value.endDate)

        return `${ymd(startDate)}~${ymd(endDate)}`
      }
    }

    onBeforeMount(async () => {
      await resetVoucherStore()
    })

    onMounted(async () => {
      try {
        await fetchUserProfile()
        await fetchVoucherDetail(voucherId)
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
      isDisableSubmit.value = false
      // 優待名が取得できた場合、webビューのタイトルに優待名を表示する。
      // 優待名が取得できなかった場合、webビューのタイトルに"優待がありません"というメッセージを表示する。
      if (!voucher.value) {
        document.title = '優待がありません'
      } else {
        document.title = voucher.value.name
      }
    })

    const goShopPage = (shopId: string) => {
      router.push({name: 'Shop', params: {shopId: shopId}})
    }

    const goReserve = () => {
      router.push({name: 'Reserve'})
    }

    const isDisableSubmit = ref(true)
    const isOpen = ref(false)

    const setIsOpen = (value: boolean) => {
      isOpen.value = value
    }

    const onSubmit = async () => {
      if (isDisableSubmit.value == true) {
        return
      }
      isDisableSubmit.value = true
      withLoading(async () => {
        try {
          await createVoucherReserve(voucherId, {
            preventShowApplicationError: true,
          })
          await sendReservedMessage() // isSendMessage.value に結果が入る

          if (isSendMessage.value) {
            await fetchVoucherDetail(voucherId)
            showSuccessToast('特別優待の利用申請を行ないました！')
          } else {
            await fetchVoucherDetail(voucherId)
            showErrorToast(
              '特別優待の利用申請が正しく行われませんでした。LINEからコンシェルジュにお問い合わせください',
            )
          }
        } catch (e) {
          if (!isApiError(e)) {
            throw e
          }

          if (e instanceof ApiApplicationError) {
            const errorCode = e.errorCode
            if (
              errorCode === ErrorCodes.ErrorCodeReachedVoucherReserveLimit ||
              errorCode ===
                ErrorCodes.ErrorCodeReachedVoucherReserveLimitPerPerson
            ) {
              showErrorToast('予約回数が上限に達しました。')
            } else if (
              errorCode ===
              ErrorCodes.ErrorCodeErrorExistVoucherReserveInprogress
            ) {
              showErrorToast('同じ優待を既に申請しています。')
            } else {
              showErrorToast('予約に失敗しました。')
              isDisableSubmit.value = false
            }
          }
        } finally {
          isOpen.value = false
        }
      })
    }

    const hasValidReserve = computed(() => {
      if (voucher.value) {
        if (_.isEmpty(voucher.value?.voucherReserves)) {
          return false
        }

        const isValidReserve = voucher.value.voucherReserves.some(
          (e) =>
            e.status === ReserveStatuses.Created ||
            e.status === ReserveStatuses.WaitForPayment ||
            e.status === ReserveStatuses.Diceded,
        )

        return isValidReserve
      }
      return false
    })

    const isReachedLimit = computed(() => {
      if (voucher.value) {
        if (_.isEmpty(voucher.value.voucherReserves)) {
          return false
        }

        if (
          voucher.value.reserveLimitPerPerson == 0 ||
          voucher.value.voucherReserves.length <
            voucher.value.reserveLimitPerPerson
        ) {
          return false
        }
        return true
      }
      return false
    })

    const addFavorite = async () => {
      if (voucher.value) {
        try {
          await addFavoriteVoucher(voucher.value.voucherId)
        } catch (e) {
          if (!isApiError(e)) {
            throw e
          }
        }
      }
    }

    const sendReservedMessage = async (): Promise<void> => {
      // 動作結果は isSendMessage.value に保持される。
      const messages: string[] = []
      if (voucher.value) {
        messages.push(
          `${VoucherReserveCodeMessageLabels[voucher.value.voucherType]}:${
            voucherReserve.value.reserveId
          }`,
        )
        messages.push(`優待タイプ:${voucher.value.categories[0].name}`)

        if (
          voucher.value.categories[0].voucherCategoryId <
          VoucherReserveMessageByCategory.length
        ) {
          // VoucherReserveMessageByCategoryに該当カテゴリ用の送信メッセージがあればそれを送信する。
          const messageDef =
            VoucherReserveMessageByCategory[
              voucher.value.categories[0].voucherCategoryId
            ]
          if (messageDef.length > 0 && messageDef[0] !== '') {
            // 1行目が空行なら、送信メッセージはないものとして扱われる。
            const additionalMessages = messageDef.map(function (mess) {
              return mess.replace('{{nickName}}', userProfile.value.nickName)
            })
            messages.push(additionalMessages.join('\n'))
          }
        }
      }
      await sendTextMessages(messages)
    }

    return {
      voucher,
      SuffixDiscountValueLabels,
      expireDate,
      goShopPage,
      isDisableSubmit,
      isOpen,
      setIsOpen,
      onSubmit,
      VoucherTypes,
      DiscountTypes,
      ExperienceUsageLabels,
      ProductUsageLabels,
      PaymentMethods,
      ExperiencePaymentLabels,
      ProductPaymentLabels,
      hasValidReserve,
      isReachedLimit,
      goReserve,
      addFavorite,
    }
  },
})
