
import {VoucherSummary} from '@/models/voucher' // eslint-disable-line no-unused-vars
import {defineComponent, PropType} from 'vue' // eslint-disable-line no-unused-vars
import {useRouter} from 'vue-router'
import {SuffixDiscountValueLabels} from '@/config/label'
import {DiscountTypes} from '@/config/enum'
import {HeartIcon} from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    HeartIcon,
  },
  props: {
    voucher: {
      type: Object as PropType<VoucherSummary>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const goVoucherDetail = () => {
      router.push({
        name: 'VoucherDetail',
        params: {voucherId: props.voucher.voucherId.toString()},
      })
    }
    return {
      goVoucherDetail,
      SuffixDiscountValueLabels,
      DiscountTypes,
    }
  },
})
