import {AuthInfo, SigninRequest, SignupRequest} from '@/models/account'
import {ApiV1Service, ApiOptions} from './common/api-service'

class AccountServiceV1 extends ApiV1Service {
  signin(req: SigninRequest, options?: ApiOptions) {
    return this.post(AuthInfo, '/accounts/signin', req, options)
  }

  signup(req: SignupRequest, options?: ApiOptions) {
    return this.post(AuthInfo, '/accounts/signup', req, options)
  }
}

export const accountService = new AccountServiceV1()
