
import {defineComponent} from 'vue'
import SProfileSnsCell from './SProfileSnsCell.vue'

export default defineComponent({
  components: {
    SProfileSnsCell,
  },
  props: {},
  setup() {},
})
