import {
  CategoryVoucher,
  FetchVouchersByCategoryIdRequest,
  FetchVouchersByCategoryIdsRequest,
  FetchVouchersRequest,
  VoucherDetail,
  VoucherSummary,
} from '@/models/voucher'
import {ApiV1Service} from './common/api-service'

class VoucherV1Service extends ApiV1Service {
  fetchVouchersByCategoryId(request: FetchVouchersByCategoryIdRequest) {
    const {categoryId, ...req} = request
    return this.getPaginatedList(
      VoucherSummary,
      `/categories/${categoryId}/vouchers`,
      req,
    )
  }

  fetchVoucherDetail(voucherId: number) {
    return this.getOpt(VoucherDetail, `/vouchers/${voucherId}`)
  }

  fetchVouchersByCategoryIds(request: FetchVouchersByCategoryIdsRequest) {
    return this.getList(VoucherSummary, `/categories`, request)
  }

  fetchVouchers(request: FetchVouchersRequest) {
    return this.getPaginatedList(VoucherSummary, '/vouchers', request)
  }

  fetchCategoryVouchers() {
    if (process.env.VUE_APP_HOME_VOUCHERS_SOURCE == 'home_voucher') {
      return this.getList(CategoryVoucher, `/home/vouchers/`)
    } else {
      // classic style
      return this.getList(CategoryVoucher, `/vouchers/categories/`)
    }
  }
}

export const voucherService = new VoucherV1Service()
