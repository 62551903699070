
import {defineComponent, ref} from 'vue'
import {Form, Field, ErrorMessage} from 'vee-validate'
import {useToastStore} from '@/stores/toast-store'
import {useAccountStore} from '@/stores/account-store'
import {useRouter} from 'vue-router'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {},
  setup() {
    const {showSuccessToast} = useToastStore()
    const {deleteUser} = useAccountStore()
    const router = useRouter()
    const reason = ref('')

    const onSubmit = async () => {
      try {
        await deleteUser(reason.value)
        showSuccessToast('退会依頼を送りました。')

        router.replace({path: '/'})
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    }

    return {
      reason,
      onSubmit,
    }
  },
})
