
import {defineComponent} from 'vue'
import {useToastStore} from '@/stores/toast-store'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/vue/24/outline'
import {ToastTypes, ToastTypeIds} from '@/config/enum'

export default defineComponent({
  components: {
    CheckCircleIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const {isShowToast, toastMessage, toastType} = useToastStore()

    return {
      isShowToast,
      toastMessage,
      toastType,
      ToastTypes,
      ToastTypeIds,
    }
  },
})
