import {ErrorCode} from '@/config/errors'

// APIのメッセージを上書きしたいものを定義する。
// 改修でメッセージを変更したい場合はここを変更する。
export const ApiApplicationErrorMessages: Record<ErrorCode, string> = {
  ErrorCodeServerInvalid:
    'エラーが発生しています。しばらくしてから再度お試しください。',
  ErrorCodeNotAuthenticated: '許可されていない操作です。',
  ErrorCodeUserNotFound: 'ユーザーが見つかりません。',
  ErrorCodeUserAuthNotFound: '認証されていません。',
  ErrorCodeInvalidParameter: 'パラメーターが不正です。',
  ErrorCodeUserAlreadyExists: 'ユーザーはすでに登録されています。',
  ErrorCodeInvalidProvider: 'ユーザー情報のプロバイダーが不正です。',
  ErrorCodeInvalidToken: '不正なコードです。',
  ErrorCodeVoucherNotFound: '特別優待が見つかりません。',
  ErrorCodeReachedVoucherReserveLimit: '優待の利用回数が上限に達しました。',
  ErrorCodeReachedVoucherReserveLimitPerPerson:
    '優待の利用回数が上限に達しました。',
  ErrorCodeFailedCreateReserve: '優待予約に失敗しました。',
  ErrorCodeSnsAlreadyExists: 'SNSアカウントがすでに登録されています。',
  ErrorCodeCampaignNotFound: 'キャンペーンが見つかりません。',
  ErrorCodeVoucherReserveNotFound: '優待予約が見つかりません。',
  ErrorCodeVoucherReserveReceiptNotFound: '優待利用履歴が見つかりません。',
  ErrorCodeInvalidVerifyCode: '不正な認証コードです。',
  ErrorCodeAddressNotFound: '住所が見つかりません。',
  ErrorCodePaymentMethodNotFound: '支払い方法が見つかりません。',
  ErrorCodeVoucherReserveAlreadyPaid: 'すでに支払いが完了しています。',
  ErrorCodeErrorExistVoucherReserveInprogress: 'すでに優待を利用中です。',
  ErrorUnsupportedOperation: 'サポートされていない操作です。',
  ErrorSignInUserNotFound: 'ログイン可能なユーザーが見つかりません。',
} as const
