
import {useGlobalStore} from '@/stores/global-store'
import {useVoucherReserveStore} from '@/stores/voucher/voucher-reserve-store'
import {defineComponent, onMounted, PropType} from 'vue' // eslint-disable-line no-unused-vars
import SReserveCard from './SReserveCard.vue'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    SReserveCard,
  },
  props: {
    reserveStatusIds: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup(props) {
    const {fetchVoucherReservesByReserveStatusId, voucherReserves} =
      useVoucherReserveStore()
    const {isLoading, startLoading, endLoading} = useGlobalStore()

    onMounted(async () => {
      const reserveStatusIds = props.reserveStatusIds.join(',')
      try {
        await fetchVoucherReservesByReserveStatusId({
          reserveStatusIds: reserveStatusIds,
          offset: 0,
          limit: 10,
        })
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    const loadMoreVoucherReserves = async () => {
      try {
        startLoading()
        const reserveStatusIds = props.reserveStatusIds.join(',')
        await fetchVoucherReservesByReserveStatusId({
          reserveStatusIds: reserveStatusIds,
          offset: voucherReserves.value.data.length,
          limit: 10,
        })
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
        voucherReserves.value.isFinal = true
      } finally {
        endLoading()
      }
    }

    return {
      voucherReserves,
      loadMoreVoucherReserves,
      isLoading,
    }
  },
})
