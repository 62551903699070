export const ErrorCodes = {
  ErrorCodeServerInvalid: 1001,
  ErrorCodeNotAuthenticated: 1002,
  ErrorCodeUserNotFound: 1003,
  ErrorCodeUserAuthNotFound: 1004,
  ErrorCodeInvalidParameter: 1005,
  ErrorCodeUserAlreadyExists: 1006,
  ErrorCodeInvalidProvider: 1007,
  ErrorCodeInvalidToken: 1008,
  ErrorCodeVoucherNotFound: 1009,
  ErrorCodeReachedVoucherReserveLimit: 1010,
  ErrorCodeReachedVoucherReserveLimitPerPerson: 1011,
  ErrorCodeFailedCreateReserve: 1012,
  ErrorCodeSnsAlreadyExists: 1013,
  ErrorCodeCampaignNotFound: 1014,
  ErrorCodeVoucherReserveNotFound: 1015,
  ErrorCodeVoucherReserveReceiptNotFound: 1016,
  ErrorCodeInvalidVerifyCode: 1017,
  ErrorCodeAddressNotFound: 1018,
  ErrorCodePaymentMethodNotFound: 1019,
  ErrorCodeVoucherReserveAlreadyPaid: 1020,
  ErrorCodeErrorExistVoucherReserveInprogress: 1021,
  ErrorUnsupportedOperation: 1022,
  ErrorSignInUserNotFound: 1023,
} as const

export type ErrorCode = keyof typeof ErrorCodes
