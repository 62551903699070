
import {defineComponent, onMounted} from 'vue'
import {useUserSnsStore} from '@/stores/user-sns-store'

export default defineComponent({
  setup() {
    const {
      instagramAuthentication,
      twitterAuthentication,
      youtubeAuthentication,
      getTwitterAuthUrl,
      getYoutubeAuthUrl,
    } = useUserSnsStore()

    onMounted(() => {
      // SProfileSnsではタイトル書き換わらないので、ここで書き換える必要あり。
      document.title = 'SNS連携'
    })

    const goInstagramAuth = () => {
      const clientId = process.env.VUE_APP_SHOW_INSTAGRAM_CLIENT_ID
      const redirectUri = process.env.VUE_APP_URL + '/profile/sns/3/auth'
      window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=user_profile,user_media`
    }

    const goTwitterAuth = async () => {
      await getTwitterAuthUrl()
    }

    const goYoutubeAuth = async () => {
      await getYoutubeAuthUrl()
    }

    return {
      instagramAuthentication,
      twitterAuthentication,
      youtubeAuthentication,
      goInstagramAuth,
      goYoutubeAuth,
      goTwitterAuth,
    }
  },
})
