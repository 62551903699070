import {AxiosError} from 'axios'

export class ApiNetworkError extends Error {
  static DEFAULT_MESSAGE =
    'ネットワークに問題が発生しています。しばらく経ってからもう一度お試しください。'
  constructor(message: string, cause?: Error) {
    super(message, {cause})
    this.name = 'ApiNetworkError'
  }

  public static isNetworkErrorAxiosResponse(e: AxiosError): boolean {
    return e.code == 'ERR_NETWORK' || e.code == 'ETIMEOUT'
  }

  public static fromAxiosError(cause: AxiosError): ApiNetworkError {
    return new ApiNetworkError(ApiNetworkError.DEFAULT_MESSAGE, cause)
  }
}
