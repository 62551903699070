
import {useCampaignStore} from '@/stores/campaign/campaign-store'
import {defineComponent, onMounted, createApp, onUpdated} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import SVoucherCard from '../voucher/SVoucherCard.vue'
import {getRichHtmlText} from '@/utils/string-utils'
import {SuffixDiscountValueLabels} from '@/config/label'
import {isApiError} from '@/services/api.v1/common/api-service'

// eslint-disable-next-line vue/one-component-per-file
export default defineComponent({
  components: {
    SVoucherCard, // eslint-disable-line vue/no-unused-components
  },
  props: {},
  setup() {
    const {fetchCampaignDetail, campaign} = useCampaignStore()
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      const campaignId = Number(route.params.campaignId as string)
      try {
        fetchCampaignDetail(campaignId)
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    onUpdated(() => {
      const vouchers = campaign.value.vouchers

      vouchers.map((e) => {
        createApp(
          // eslint-disable-next-line vue/one-component-per-file
          defineComponent({
            extends: SVoucherCard,
            computed: {
              SuffixDiscountValueLabels() {
                return SuffixDiscountValueLabels
              },
            },
            methods: {
              goVoucherDetail() {
                router.push({
                  name: 'VoucherDetail',
                  params: {voucherId: e.voucherId.toString()},
                })
              },
            },
          }),
          // eslint-disable-next-line vue/one-component-per-file
          {
            voucher: e,
          },
        ).mount(`#voucher_card_${e.voucherId}`)
      })
    })

    return {
      campaign,
      getRichHtmlText,
    }
  },
})
