export class UserProfile {
  nickName: string = ''
  imageUrl: string = ''
  firstName: string = ''
  lastName: string = ''
  firstNameKana: string = ''
  lastNameKana: string = ''
  genderId: number = 0
  birthYear: string = '2000'
  birthMonth: string = '1'
  birthDate: string = '1'
  prefectureId: number = 1
  phoneNumber: string = ''
}

export class User {
  userId: number = 0
  userProfile: UserProfile = new UserProfile()
  userAddresses: UserAddress[] = []
  userPaymentMethods: UserPaymentMethod[] = []
}

export class UserAddress {
  addressId?: number
  name: string = ''
  zipCode: string = ''
  prefectureId: number = 1
  cityName: string = ''
  streetName: string = ''
  buildingName: string = ''
  phoneNumber: string = ''
  isDefault: boolean = false
}

export class UserPaymentMethod {
  paymentMethodId?: string
  last4!: string
  exp_month!: number
  exp_year!: number
  brand: string = ''
  isDefault: boolean = false
}

export type UserDeleteRequest = {
  reason: string
}

export type CreateUserPaymentMethodRequest = {
  token: string
  isDefault: boolean
}

export type CreateUserAddressRequest = {
  userAddressId?: number
  name: string
  zipCode: string
  prefectureId: number
  cityName: string
  streetName: string
  buildingName: string
  phoneNumber: string
}
