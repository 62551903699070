
import {defineComponent, onMounted, ref, computed} from 'vue'
import {Form, Field, ErrorMessage} from 'vee-validate'
import {GenderLabels} from '@/config/label'
import {GenderIds} from '@/config/enum'
import {ChevronDownIcon} from '@heroicons/vue/24/outline'
import {useMasterStore} from '@/stores/master-store'
import {useProfileStore} from '@/stores/profile-store'
import {useGlobalStore} from '@/stores/global-store'
import {ymd} from '@/utils/date-utils'
import {useToastStore} from '@/stores/toast-store'
import {useRouter} from 'vue-router'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
    ChevronDownIcon,
  },
  props: {},
  setup() {
    const {prefectures, fetchPrefectures} = useMasterStore()
    const {userProfile, fetchUserProfile, updateUserProfile} = useProfileStore()
    const {isLoading, startLoading, endLoading} = useGlobalStore()
    const {showSuccessToast} = useToastStore()
    const router = useRouter()

    const genderItems = Object.values(GenderIds).map((e) => {
      return {
        id: e,
        name: GenderLabels[e],
      }
    })

    onMounted(async () => {
      try {
        // 選択肢の初期化
        await fetchPrefectures()
        await fetchUserProfile()
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    const isShowDatePicker = ref(false)

    const currentDate = ref(['2000', '1', '1'])

    const minDate = new Date(1900, 0, 1)
    const maxDate = new Date()

    const userBirthDay = computed(() => {
      if (
        !!userProfile.value.birthYear &&
        !!userProfile.value.birthMonth &&
        !!userProfile.value.birthDate
      ) {
        return ymd(
          new Date(
            Number(userProfile.value.birthYear),
            Number(userProfile.value.birthMonth) - 1,
            Number(userProfile.value.birthDate),
          ),
        )
      }
      return ''
    })

    const showDatePicker = () => {
      isShowDatePicker.value = true
    }

    const confirmDatePick = (values: any) => {
      isShowDatePicker.value = false
      userProfile.value.birthYear = values.selectedValues[0]
      userProfile.value.birthMonth = values.selectedValues[1]
      userProfile.value.birthDate = values.selectedValues[2]
    }

    const cancelDatePick = () => {
      isShowDatePicker.value = false
    }

    const onSubmit = async () => {
      try {
        startLoading()
        await updateUserProfile()
        showSuccessToast('プロフィールを更新しました')
        router.replace({path: '/profile'})
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      } finally {
        endLoading()
      }
    }

    return {
      genderItems,
      currentDate,
      minDate,
      maxDate,
      isShowDatePicker,
      showDatePicker,
      confirmDatePick,
      cancelDatePick,
      prefectures,
      isLoading,
      userProfile,
      userBirthDay,
      onSubmit,
    }
  },
})
