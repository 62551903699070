import {Shop} from '@/models/shop'
import {VoucherSummary} from '@/models/voucher'
import {ApiV1Service} from './common/api-service'

class ShopV1Service extends ApiV1Service {
  fetchShop(shopId: number) {
    return this.get(Shop, `/shops/${shopId}`)
  }

  fetchShopVouchers(shopId: number) {
    return this.getList(VoucherSummary, `/shops/${shopId}/vouchers`)
  }
}

export const shopService = new ShopV1Service()
