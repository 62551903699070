export function getRichHtmlText(text: string, hiddenHtmlTags?: boolean) {
  // convert link to http(s)
  text = text.replace(
    /[^="'](https?:\/\/[\w/:%#$&?()~.=+-]+)/gi,
    `<br><a href='$1' target='_blank'>$1</a>`,
  )

  // convert newline to br-tag
  text = text.replace(/\n/gi, '<br />')

  if (hiddenHtmlTags) {
    return text.replace(/(<\/?(?:br)[^>]*>)|<[^>]+>/gi, '$1')
  }

  return text
}

export function isLinkToOut(text: string) {
  return text.match(/(https?:\/\/[\w/:%#$&?()~.=+-]+)/gi)
}

export function getSmallCategoryTitle(text: string) {
  // convert newline to br-tag
  text = text.replace(/・/gi, '<br />')

  return text
}
