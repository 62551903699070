import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-lg m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SNotifications = _resolveComponent("SNotifications")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SNotifications),
    _withDirectives(_createElementVNode("div", null, "loading", 512), [
      [_vShow, _ctx.isLoadingForSignin]
    ]),
    _createVNode(_component_router_view)
  ]))
}