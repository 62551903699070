import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "min-h-screen bg-gray-lightShow" }
const _hoisted_2 = { class: "p-4 pb-20" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tab = _resolveComponent("Tab")!
  const _component_TabList = _resolveComponent("TabList")!
  const _component_SReserveList = _resolveComponent("SReserveList")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabPanels = _resolveComponent("TabPanels")!
  const _component_TabGroup = _resolveComponent("TabGroup")!
  const _component_SBottomBar = _resolveComponent("SBottomBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_TabGroup, { "selected-index": _ctx.selectedTabIndex }, {
            default: _withCtx(() => [
              _createVNode(_component_TabList, { class: "flex space-x-1" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.ReserveTabItemIds), (tabId) => {
                    return (_openBlock(), _createBlock(_component_Tab, {
                      key: tabId,
                      as: "template"
                    }, {
                      default: _withCtx(({selected}) => [
                        _createElementVNode("button", {
                          class: _normalizeClass([
                    'w-full py-2.5 text-sm font-medium leading-5',
                    selected ? 'border-b-2 border-black' : 'text-gray-400',
                  ]),
                          onClick: ($event: any) => (_ctx.selectTab(tabId))
                        }, _toDisplayString(_ctx.ReserveTabItemLabels[tabId]), 11, _hoisted_3)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_TabPanels, { class: "mt-2" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.ReserveTabItemIds), (item) => {
                    return (_openBlock(), _createBlock(_component_TabPanel, { key: item }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createVNode(_component_SReserveList, {
                            "reserve-status-ids": _ctx.ReserveTabStatuses[item]
                          }, null, 8, ["reserve-status-ids"])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["selected-index"])
        ])
      ])
    ]),
    _createVNode(_component_SBottomBar)
  ]))
}