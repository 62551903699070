import {Campaign, FetchCampaignRequest} from '@/models/campaign'
import {campaignService} from '@/services/api.v1/campaign-service'
import {
  DefaultPaginatedList,
  PaginatedList,
} from '@/services/api.v1/common/api-service'
import {reactive, toRefs} from 'vue'

type State = {
  campaign: Campaign
  campaigns: PaginatedList<Campaign>
}

const state = reactive<State>({
  campaign: new Campaign(),
  campaigns: DefaultPaginatedList,
})

export function useCampaignStore() {
  const fetchCampaignDetail = async (campaignId: number) => {
    const res = await campaignService.fetchCampaignDetail(campaignId)
    state.campaign = res
  }
  const fetchCampaigns = async (request: FetchCampaignRequest) => {
    const res = await campaignService.fetchCampaigns(request)
    state.campaigns = res
  }
  return {
    fetchCampaigns,
    fetchCampaignDetail,
    ...toRefs(state),
  }
}

export type CampaignStore = ReturnType<typeof useCampaignStore>
