
import {defineComponent, computed} from 'vue'
import SBottomBar from '@/components/SBottomBar.vue'
import {TabGroup, TabList, Tab, TabPanels, TabPanel} from '@headlessui/vue'
import {ReserveTabItemIds, ReserveTabStatuses} from '@/config/enum'
import SReserveList from './SReserveList.vue'
import {ReserveTabItemLabels} from '@/config/label'
import {useRoute, useRouter} from 'vue-router'

export default defineComponent({
  components: {
    SBottomBar,
    TabGroup,
    TabList,
    Tab,
    TabPanel,
    TabPanels,
    SReserveList,
  },
  props: {},
  setup() {
    const route = useRoute()
    const router = useRouter()

    const selectedTabIndex = computed(() => {
      const tabId = Number(route.query.tab as string)

      if (!tabId) {
        return 0
      }

      const tabIndex = Object.values(ReserveTabItemIds).findIndex(
        (e) => e === tabId,
      )
      return tabIndex
    })

    const selectTab = (tabId: number) => {
      router.push({name: 'Reserve', query: {tab: tabId.toString()}})
    }

    return {
      ReserveTabItemIds,
      ReserveTabItemLabels,
      ReserveTabStatuses,
      selectedTabIndex,
      selectTab,
    }
  },
})
