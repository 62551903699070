import axios from 'axios'

const API_TWITTER = axios.create({
  baseURL: 'https://api.twitter.com',
  timeout: 60000,
  headers: {},
})

API_TWITTER.interceptors.request.use((req: any) => {
  if (!req.headers) {
    return {}
  }

  req.headers[
    'Authorization'
  ] = `OAuth oauth_consumer_key="${process.env.VUE_APP_TWITTER_CONSUMER_PUBLIC_KEY}"`

  req.headers['Accept'] = 'application/json'
  req.headers['Content-Type'] = 'application/json'

  req.params = {...req.params}
  return req
})

class TwitterServiceV1 {
  getUserToken() {
    return API_TWITTER.post('/oauth/request_token')
  }
}

export const twitterService = new TwitterServiceV1()
