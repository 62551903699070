import {ProviderId, ProviderIds, SnsAuthenticationStatuses} from '@/config/enum'
import {RegistUserSnsRequest, UserSns} from '@/models/user-sns'
import {snsService} from '@/services/api.v1/user-sns-service'
import {reactive, toRefs} from 'vue'
import {twitterService} from '@/services/api.v1/sns/twitter-service'
import {youtubeService} from '@/services/api.v1/sns/youtube-service'

type State = {
  userSnsList: UserSns[]
}

const state = reactive<State>({
  userSnsList: [],
})

const defaultUserSns = (providerId: ProviderId) => {
  return new UserSns().defaultUserSns(providerId)
}

export function useUserSnsStore() {
  const fetchuserSnsList = async () => {
    const res = await snsService.fetchUserSnsList()
    state.userSnsList = res
  }

  const registUserSns = async (code: string, providerId: number) => {
    const request: RegistUserSnsRequest = {
      code,
      providerId,
    }
    return await snsService.registUserSns(request)
  }

  const getTwitterAuthUrl = async () => {
    const token = await twitterService.getUserToken()
    alert(token)
  }

  const getYoutubeAuthUrl = async () => {
    const authUrl = await youtubeService.getAuthURL()
    window.location.href = authUrl
  }

  const isSnsAuthenticated = () => {
    return state.userSnsList.some(
      (e) => e.status === SnsAuthenticationStatuses.Done,
    )
  }

  const instagramAuthentication = () => {
    return (
      state.userSnsList.find((e) => e.providerId === ProviderIds.Instagram) ||
      defaultUserSns(ProviderIds.Instagram)
    )
  }

  const twitterAuthentication = () => {
    return (
      state.userSnsList.find((e) => e.providerId === ProviderIds.Twitter) ||
      defaultUserSns(ProviderIds.Twitter)
    )
  }

  const youtubeAuthentication = () => {
    return (
      state.userSnsList.find((e) => e.providerId === ProviderIds.Youtube) ||
      defaultUserSns(ProviderIds.Youtube)
    )
  }

  return {
    fetchuserSnsList,
    registUserSns,
    getTwitterAuthUrl,
    getYoutubeAuthUrl,
    isSnsAuthenticated,
    instagramAuthentication,
    twitterAuthentication,
    youtubeAuthentication,
    ...toRefs(state),
  }
}

export type UserSnsStore = ReturnType<typeof useUserSnsStore>
