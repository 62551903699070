
import {defineComponent, onMounted, watch, computed} from 'vue'
import SBottomBar from '@/components/SBottomBar.vue'
import SBrowseFilter from './SBrowseFilter.vue'
import {useMasterStore} from '@/stores/master-store'
import {useVoucherStore} from '@/stores/voucher/voucher-store'
import {useGlobalStore} from '@/stores/global-store'
import SVoucherCard from '@/views/voucher/SVoucherCard.vue'
import {useRoute} from 'vue-router'
import {useToastStore} from '@/stores/toast-store'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    SBottomBar,
    SBrowseFilter,
    SVoucherCard,
  },
  props: {},
  setup() {
    const {fetchVouchers, vouchers} = useVoucherStore()
    const {isLoading, startLoading, endLoading} = useGlobalStore()
    const {withLoading} = useToastStore()
    const {voucherCategories, fetchVoucherCategories} = useMasterStore()
    const route = useRoute()

    const categoryId = Number(route.query.category as string)

    const voucherCategory = computed(() => {
      const categoryId = Number(route.query.category as string)

      return voucherCategories.value.find(
        (e) => Number(e.voucherCategoryId) === categoryId,
      )
    })

    onMounted(() => {
      withLoading(async () => {
        await Promise.all([
          fetchVoucherCategories,
          fetchVouchers({
            limit: 5,
            offset: 0,
            filters: {
              tag: route.query.tag as string,
              category: route.query.category as string,
            },
          }),
        ])
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    watch(route, () => {
      withLoading(async () => {
        await fetchVouchers({
          limit: 5,
          offset: 0,
          filters: {
            tag: route.query.tag as string,
            category: route.query.category as string,
          },
        })
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const loadMoreVouchers = async () => {
      try {
        startLoading()
        await fetchVouchers({
          offset: vouchers.value.data.length,
          limit: 5,
          filters: {
            tag: route.query.tag as string,
            category: route.query.category as string,
          },
        })
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      } finally {
        endLoading()
      }
    }

    return {
      vouchers,
      loadMoreVouchers,
      isLoading,
      voucherCategory,
      categoryId,
    }
  },
})
