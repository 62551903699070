import {
  FetchVouchersByCategoryIdRequest,
  VoucherSummary,
  VoucherDetail,
  FetchVouchersRequest,
  CategoryVoucher,
} from '@/models/voucher'
import {voucherService} from '@/services/api.v1/voucher-service'
import {
  DefaultPaginatedList,
  PaginatedList,
} from '@/services/api.v1/common/api-service'
import {reactive, toRefs} from 'vue'
import {PaginationRequest} from '@/models/common/pagination'
import {userFavoriterVoucherService} from '@/services/api.v1/user-favorite-voucher-service'
import {VoucherFilter} from '@/models/voucher-filter'

type State = {
  vouchers: PaginatedList<VoucherSummary>
  voucher: VoucherDetail | undefined

  categoryVouchers: CategoryVoucher[]

  filters: VoucherFilter
}

const initialState = () => {
  return {
    vouchers: DefaultPaginatedList,
    voucher: new VoucherDetail(),
    categoryVouchers: [],
    filters: new VoucherFilter(),
  }
}

const state = reactive<State>(initialState())

export function useVoucherStore() {
  const resetVoucherStore = async () => {
    Object.assign(state, initialState())
  }
  const fetchVouchersByCategoryId = async (
    request: FetchVouchersByCategoryIdRequest,
  ) => {
    const res = await voucherService.fetchVouchersByCategoryId(request)

    if (request.offset !== 0) {
      const currentData = state.vouchers.data
      state.vouchers.data = [...currentData, ...res.data]
      state.vouchers.isFinal = res.isFinal
      state.vouchers.totalCount = res.totalCount
      return
    }

    state.vouchers = res
  }

  const fetchVoucherDetail = async (voucherId: number) => {
    state.voucher = new VoucherDetail()
    const res = await voucherService.fetchVoucherDetail(voucherId)
    state.voucher = res
  }

  const fetchCategoryVouchers = async () => {
    const res = await voucherService.fetchCategoryVouchers()
    state.categoryVouchers = res
  }

  const fetchVouchers = async (request: FetchVouchersRequest) => {
    const res = await voucherService.fetchVouchers(request)

    if (request.offset !== 0) {
      const currentData = state.vouchers.data
      state.vouchers.data = [...currentData, ...res.data]
      state.vouchers.isFinal = res.isFinal
      state.vouchers.totalCount = res.totalCount
      return
    }

    state.vouchers = res
  }

  const fetchUserFavoriteVouchers = async (request: PaginationRequest) => {
    const res =
      await userFavoriterVoucherService.fetchUserFavoriteVoucehrs(request)

    if (request.offset !== 0) {
      const currentData = state.vouchers.data
      state.vouchers.data = [...currentData, ...res.data]
      state.vouchers.isFinal = res.isFinal
      state.vouchers.totalCount = res.totalCount
      return
    }

    state.vouchers = res
  }

  const addFavoriteVoucher = async (voucherId: number) => {
    await userFavoriterVoucherService.addFavoriteVoucher(voucherId)
    await fetchVoucherDetail(voucherId)
  }

  const saveFilters = async (filters: VoucherFilter) => {
    state.filters = filters
  }

  const setVoucherDetail = (voucher: VoucherDetail) => {
    state.voucher = voucher
  }

  return {
    resetVoucherStore,
    fetchVouchersByCategoryId,
    fetchCategoryVouchers,
    fetchUserFavoriteVouchers,
    fetchVoucherDetail,
    fetchVouchers,
    addFavoriteVoucher,
    saveFilters,
    setVoucherDetail,

    ...toRefs(state),
  }
}

export type VoucherStore = ReturnType<typeof useVoucherStore>
