import {VoucherDetail} from './voucher'
import {ReserveStatus} from '@/config/enum'
import {PaginationRequest} from './common/pagination'
import {UserAddress, UserPaymentMethod} from './user'

export class VoucherReserveSummary {
  reserveId?: number
  influencerAddressId?: number | null
  status!: ReserveStatus
  orderCount: number = 1
  additionalCost: number = 0
  totalPrice: number = 0
  voucher?: VoucherDetail
  userAddress?: UserAddress
  createDate?: Date
}

export class VoucherReserveDetail extends VoucherReserveSummary {
  voucherReserveReceipt?: VoucherReserveReceipt
  isExternalPayment: boolean = false
}

export class VoucherReserveReceipt {
  receiptId?: number
  paymentStatus: number = 1
  userPaymentMethod?: UserPaymentMethod
  isVerified: boolean = false
}

export type FetchVoucherReservesByReserveStatusIdRequest = {
  reserveStatusIds: string
} & PaginationRequest

export type VerifyVoucherReserveRequest = {
  voucherReserveId: number
  verifyCode: string
}

export class PayVoucherReserveInfo {
  voucherReserveId!: number
  address?: UserAddress
  paymentMethod?: UserPaymentMethod
}

export type PayVoucherReserveRequest = {
  voucherReserveId: number
  addressId?: number | null
  paymentMethodId: string
}
