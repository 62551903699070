import {
  DiscountType,
  DiscountTypes,
  PaymentMethod,
  PaymentMethods,
  VoucherType,
  VoucherTypes,
} from '@/config/enum'
import {PaginationRequest} from './common/pagination'
import {VoucherCategory} from './master/voucherCategory'
import {VoucherReserveSummary} from './voucher-reserve'

class ClientShop {
  shopId?: number
  name: string = ''
  imageUrl: string = ''
  url: string = ''
}

class VoucherImage {
  id?: number
  voucherImageId?: number
  imageUrl: string = ''
}

export class VoucherSummary {
  voucherId!: number
  voucherCode!: string
  name: string = ''
  shop: ClientShop = new ClientShop()
  images: VoucherImage[] = []
  summary: string = ''
  description: string = ''
  spec: string = ''
  categories: VoucherCategory[] = []
  voucherType: VoucherType = VoucherTypes.Experience
  price: number = 0
  discountedPrice: number = 0
  discountType: DiscountType = DiscountTypes.NoDiscount
  discountValue: number = 0
  paymentMethod: PaymentMethod = PaymentMethods.CreditCard
  relatedUrl: string = ''
  startDate: Date = new Date()
  endDate: Date = new Date()
  reserveLimit: number = 0
  reserveLimitPerPerson: number = 0
  orderLimit: number = 0
  orderLimitPerPerson: number = 0

  favorite: boolean = false
  voucherReserves: VoucherReserveSummary[] = []
}

export class VoucherDetail extends VoucherSummary {
  conditionOfUse: string = ''
  hasPrivilege: boolean = false
  hasTemporarilyReservation: boolean = false
  isNeedVerify: boolean = false
}

export type FetchVouchersByCategoryIdRequest = {
  categoryId: number
} & PaginationRequest

export type FetchVouchersByCategoryIdsRequest = {
  categoryIds: string
} & PaginationRequest

export type FetchVouchersRequest = {} & PaginationRequest

export class CategoryVoucher {
  category: VoucherCategory = new VoucherCategory()
  vouchers: VoucherSummary[] = []
}
