import {Empty} from '@/models/common/empty'
import {RegistUserSnsRequest, UserSns} from '@/models/user-sns'
import {ApiV1Service} from './common/api-service'

class SnsServiceV1 extends ApiV1Service {
  fetchUserSnsList() {
    return this.getList(UserSns, '/users/me/sns')
  }

  registUserSns(request: RegistUserSnsRequest) {
    return this.post(Empty, `/users/me/sns`, request)
  }

  // generateTwitterAuthURL() {
  //   return this.get()
  // }
}

export const snsService = new SnsServiceV1()
