
import {defineComponent, onMounted, computed} from 'vue'
import SBottomBar from '@/components/SBottomBar.vue'
import SProfileSnsAuthDialog from '@/views/profile/sns/SProfileSnsAuthDialog.vue'
import {useSessionLiffStore} from '@/stores/session-liff-store'
import {useBannerStore} from '@/stores/banner/banner-store'
import {useMasterStore} from '@/stores/master-store'
import {useRouter} from 'vue-router'
import {getSmallCategoryTitle, isLinkToOut} from '@/utils/string-utils'
import {useVoucherStore} from '@/stores/voucher/voucher-store'
import {SuffixDiscountValueLabels} from '@/config/label'
import {DiscountTypes} from '@/config/enum'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    SBottomBar,
    SProfileSnsAuthDialog,
  },
  setup() {
    const {lineProfile} = useSessionLiffStore()
    const {banners, fetchBanners} = useBannerStore()
    const {saveFilters, fetchCategoryVouchers, categoryVouchers} =
      useVoucherStore()
    const {
      voucherCategories,
      popularTags,
      fetchVoucherCategories,
      fetchPopularTags,
    } = useMasterStore()

    const router = useRouter()

    onMounted(async () => {
      await Promise.all([
        fetchBanners(),
        fetchVoucherCategories(),
        fetchPopularTags(),
        fetchCategoryVouchers(),
      ]).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const campaignWidth = computed(() => {
      return window.innerWidth * 0.9
    })

    const categoryVoucherWidth = computed(() => {
      return window.innerWidth * 0.45
    })

    const goBrowseCategory = (categoryId: number) => {
      const selectedCategory = voucherCategories.value.find(
        (e) => e.voucherCategoryId === categoryId,
      )

      if (selectedCategory) {
        saveFilters({category: selectedCategory, tags: []})
      }

      router.push({
        name: 'Browse',
      })
    }

    const goLinkedBanner = (pageUrl: string) => {
      if (isLinkToOut(pageUrl)) {
        window.location.href = pageUrl
        return
      }
      router.push({
        path: pageUrl,
      })
    }

    const goBrowse = (tagId: number) => {
      const selectedTag = popularTags.value.find(
        (e) => e.voucherTagId === tagId,
      )

      if (selectedTag) {
        saveFilters({tags: [selectedTag]})
      }

      router.push({
        name: 'Browse',
      })
    }

    const goCampaignDetail = (campaignId: number) => {
      router.push({
        name: 'CampaignDetail',
        params: {campaignId: campaignId},
      })
    }

    const goVoucherDetail = (voucherId: number) => {
      router.push({
        name: 'VoucherDetail',
        params: {voucherId: voucherId},
      })
    }

    const env = process.env.NODE_ENV

    return {
      lineProfile,
      banners,
      voucherCategories,
      popularTags,
      campaignWidth,
      categoryVoucherWidth,
      categoryVouchers,
      SuffixDiscountValueLabels,
      DiscountTypes,
      getSmallCategoryTitle,
      goBrowseCategory,
      goLinkedBanner,
      goBrowse,
      goCampaignDetail,
      goVoucherDetail,
      env,
    }
  },
})
