import {Banner} from '@/models/banner'
import {ApiV1Service} from './common/api-service'

class BannerServiceV1 extends ApiV1Service {
  fetchBanners() {
    return this.getList(Banner, `/banners`)
  }
}

export const bannerService = new BannerServiceV1()
