
import {computed, defineComponent} from 'vue'
import {useRouter} from 'vue-router'
import {useSessionStore} from '@/stores/session-store'

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const router = useRouter()
    const {isLogined} = useSessionStore()

    const goNextPage = () => {
      router.push({path: '/'})
    }

    const buttonText = computed(() => (isLogined ? 'トップページへ' : '再認証'))
    return {
      goNextPage,
      buttonText,
    }
  },
})
