import {Prefecture} from '@/models/master/prefecture'
import {VoucherCategory} from '@/models/master/voucherCategory'
import {VoucherTag} from '@/models/master/voucherTag'
import {masterServiceV1} from '@/services/api.v1/master-service'
import {reactive, toRefs} from 'vue'

type State = {
  prefectures: Prefecture[]
  voucherCategories: VoucherCategory[]
  voucherTags: VoucherTag[]
  popularTags: VoucherTag[]
}

const state = reactive<State>({
  prefectures: [],
  voucherCategories: [],
  voucherTags: [],
  popularTags: [],
})

const POPULAR_TAG_LIMIT = 7

export function useMasterStore() {
  return {
    async fetchPrefectures() {
      const res = await masterServiceV1.fetchPrefectures()
      state.prefectures = res
    },
    async fetchVoucherCategories() {
      const res = await masterServiceV1.fetchVoucherCategories()
      state.voucherCategories = res
    },
    async fetchVoucherTags() {
      const res = await masterServiceV1.fetchVoucherTags()
      state.voucherTags = res
    },
    async fetchPopularTags() {
      const res = await masterServiceV1.fetchPopularTags(POPULAR_TAG_LIMIT)
      state.popularTags = res
    },
    ...toRefs(state),
  }
}

export type MasterStore = ReturnType<typeof useMasterStore>
