
import {defineComponent, ref, onMounted} from 'vue'
import {Form, Field, ErrorMessage} from 'vee-validate'
import {UserAddress} from '@/models/user'
import {useProfileStore} from '@/stores/profile-store'
import {useMasterStore} from '@/stores/master-store'
import {useToastStore} from '@/stores/toast-store'
import {CheckIcon, XMarkIcon} from '@heroicons/vue/24/outline'
import {showConfirmDialog} from 'vant'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
    CheckIcon,
    XMarkIcon,
  },
  props: {},
  setup() {
    const showAddAddressPopup = ref(false)

    const address = ref<UserAddress>(new UserAddress())
    const {
      userAddresses,
      fetchUserAddresses,
      createOrUpdateUserAddress,
      deleteUserAddress,
      setDefaultUserAddress,
    } = useProfileStore()
    const {prefectures, fetchPrefectures} = useMasterStore()

    const {showSuccessToast, withLoading} = useToastStore()

    onMounted(() => {
      withLoading(async () => {
        await Promise.all([fetchUserAddresses(), fetchPrefectures()])
      }).catch((e) => {
        if (!isApiError(e)) {
          throw e
        }
      })
    })

    const openAddAddressPopup = () => {
      showAddAddressPopup.value = true
    }

    const saveUserAddress = async () => {
      await withLoading(async () => {
        try {
          await createOrUpdateUserAddress(address.value)
          showSuccessToast('住所を追加しました。')
        } catch (e) {
          if (!isApiError(e)) {
            throw e
          }
        } finally {
          showAddAddressPopup.value = false
        }
      })
    }

    const setIsDefault = async (addressId: number) => {
      showConfirmDialog({
        title: 'デフォルトに設定する',
        message: 'この住所をデフォルトに設定しますか？',
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
      })
        .then(() => {
          return withLoading(async () => {
            await setDefaultUserAddress(addressId)
          }).catch((e) => {
            if (!isApiError(e)) {
              throw e
            }
          })
        })
        .catch(() => {
          console.log('cancel')
        })
    }

    const deleteAddress = (addressId: number) => {
      showConfirmDialog({
        title: '削除します',
        message: 'この住所を削除しますか？',
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
      })
        .then(() => {
          return withLoading(async () => {
            await deleteUserAddress(addressId)
          }).catch((e) => {
            if (!isApiError(e)) {
              throw e
            }
          })
        })
        .catch(() => {})
    }

    return {
      showAddAddressPopup,
      openAddAddressPopup,
      saveUserAddress,
      address,
      userAddresses,
      prefectures,
      deleteAddress,
      setIsDefault,
    }
  },
})
