
import {defineComponent, onMounted} from 'vue'
import SBottomBar from '@/components/SBottomBar.vue'
import {UserIcon} from '@heroicons/vue/20/solid'
import {
  EnvelopeIcon,
  ChevronRightIcon,
  ShieldExclamationIcon,
  UserMinusIcon,
  ArrowRightOnRectangleIcon,
  DocumentTextIcon,
  CreditCardIcon,
  MapPinIcon,
} from '@heroicons/vue/24/outline'
import {useProfileStore} from '../../stores/profile-store'
import {useRouter} from 'vue-router'
import {useSessionLiffStore} from '@/stores/session-liff-store'
import {isApiError} from '@/services/api.v1/common/api-service'

export default defineComponent({
  components: {
    SBottomBar,
    UserIcon,
    EnvelopeIcon,
    ChevronRightIcon,
    ShieldExclamationIcon,
    UserMinusIcon,
    ArrowRightOnRectangleIcon,
    DocumentTextIcon,
    CreditCardIcon,
    MapPinIcon,
  },
  props: {},
  setup() {
    const {fetchUserProfile, userProfile} = useProfileStore()
    const {lineProfile} = useSessionLiffStore()
    const router = useRouter()

    onMounted(async () => {
      try {
        fetchUserProfile()
      } catch (e) {
        if (!isApiError(e)) {
          throw e
        }
      }
    })

    const goPrivacy = () => {
      router.push({name: 'Privacy'})
    }

    const goTerms = () => {
      router.push({name: 'Terms'})
    }

    const goProfileDelete = () => {
      router.push({name: 'ProfileDelete'})
    }

    const goProfileSns = () => {
      router.push({name: 'ProfileSns'})
    }

    const goProfileEditor = () => {
      router.push({name: 'ProfileEditor'})
    }

    const goPaymentList = () => {
      router.push({name: 'Payment'})
    }

    const goAddressList = () => {
      router.push({name: 'Address'})
    }

    return {
      userProfile,
      lineProfile,
      goPrivacy,
      goTerms,
      goProfileDelete,
      goProfileSns,
      goProfileEditor,
      goPaymentList,
      goAddressList,
    }
  },
})
