import {Shop} from '@/models/shop'
import {VoucherSummary} from '@/models/voucher'
import {shopService} from '@/services/api.v1/shop-service'
import {reactive, toRefs} from 'vue'

type State = {
  shop: Shop
  vouchers: VoucherSummary[]
}

const initialState = () => {
  return {
    shop: new Shop(),
    vouchers: [],
  }
}

const state = reactive<State>(initialState())

export function useShopStore() {
  const resetShopStore = async () => {
    Object.assign(state, initialState())
  }
  const fetchShop = async (shopId: number) => {
    const res = await shopService.fetchShop(shopId)
    state.shop = res
  }

  const setShop = (shop: Shop) => {
    state.shop = shop
  }

  const fetchShopVouchers = async (shopId: number) => {
    const res = await shopService.fetchShopVouchers(shopId)
    state.vouchers = res
  }

  return {
    resetShopStore,
    fetchShop,
    setShop,
    fetchShopVouchers,

    ...toRefs(state),
  }
}

export type ShopStore = ReturnType<typeof useShopStore>
