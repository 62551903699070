import 'moment/locale/ja'
import moment from 'moment'

export function ymd(value: Date): string {
  if (!value) return ''
  return moment(value).format('YYYY年M月D日')
}

export function ym(value: Date): string {
  if (!value) return ''
  return moment(value).format('YYYY-MM')
}

export function ymdhm(value: Date): string {
  if (!value) return ''
  return moment(value).format('YYYY年MM月DD日 HH:mm')
}

export function ymdhms(value: Date): string {
  if (!value) return ''
  return moment(value).format('YYYY年MM月DD日 HH:mm:ss')
}

export function hm(value: Date): string {
  if (!value) return ''
  return moment(value).format('HH : mm')
}

export function nullableYmd(value: Date): string | null {
  if (!value) return null
  return moment(value).format('YYYY-MM-DD')
}

export function nullableYm(value: Date): string | null {
  if (!value) return null
  return moment(value).format('YYYY-MM')
}

export function fromNow(value: Date) {
  return moment(value).fromNow()
}

export function mdw(value: Date) {
  if (!value) return ''
  return moment(value).format('M月D日 (ddd)')
}

// 09:00 => 9:00
export function formatHm(hmStr: string) {
  const [hour, minute] = hmStr.split(':')
  return `${Number(hour)}:${minute}`
}
