import {ProviderId} from '@/config/enum'

export class AuthInfo {
  token: string = ''
}

export type SigninRequest = {
  providerId: ProviderId
} & SigninByEmailAndPassword &
  SigninByLineToken

type SigninByEmailAndPassword = {
  email?: string
  password?: string
}

type SigninByLineToken = {
  token?: string
}

export type LineProfile = {
  userId: string
  displayName: string
  pictureUrl?: string
  statusMessage?: string
}

export type SignupRequest = {
  providerId: ProviderId
  signupToken: string
} & SignupBaseParams &
  SignupByEmailAndPassword &
  SignupByLineToken

type SignupByEmailAndPassword = {
  email?: string
  password?: string
}

type SignupByLineToken = {
  lineId?: string
  lineToken?: string
}

export type SignupBaseParams = {
  nickName: string
  genderId: number | null
  prefectureId: number | null
}
